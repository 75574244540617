import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers";
import {
  CuponsChartCard,
  VendasChartCard,
  ClientesChartCard,
  NovosClientesChartCard,
  ClientesNaoRecorrentesChartCard,
  ClientesRecorrentesChartCard,
  TrocasChartCard,
  FidelizacoesTiposChartCard,
  FidelizacoesTrocasChartCard,
  TopProdutosFidelizadosChartCard,
  TopProdutosTrocadosChartCard,
  TopClientesChartCard,
  ClientesAniversariantesChartCard,
  GraficoClientesSexoChartCard,
  GraficoClientesFaixaEtariaChartCard,
  GraficoClientesRecorrenciaDiasChartCard
} from './components'
import { Page } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAction } from '_actions'
import moment from 'moment'
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
//import AppBar from '@material-ui/core/AppBar';
//import Tabs from '@material-ui/core/Tabs';
//import Tab from '@material-ui/core/Tab';
//import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from "@material-ui/lab";
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import SwapHorizTwoToneIcon from '@material-ui/icons/SwapHorizTwoTone';
import palette from 'theme/palette'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
  },
  chips: {
    alignContent: 'center',
    justifyContent: 'center',
    "&&:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      transition: 0.3
    },
    "&&:focus": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      transition: 0.3
    }
  },
  //rootPanel: {
  //  flexGrow: 1,
  //  width: '100%',
  //  backgroundColor: theme.palette.background.paper,
  //},
}))

const Dashboard = () => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const [dataInicio, setDataInicio] = useState(moment().startOf('month'))
  const [dataFim, setDataFim] = useState(moment())

  const [tab, setTab] = useState(0);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { isLoading } = useSelector((state) => state.dashboard)

  const mensagens = useSelector((state) => (state.dashboard ? state.dashboard.mensagens : ''))
  const ultimaVendaImportada = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.ultimaVendaImportada : '') : ''))

  useEffect(() => {
    setTimeout(function () {
      //if (newState == -1) {
      handleClick();
      //}
    }, 1000);

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(dashboardAction.clear())

  }, [idLoja, dataInicio, dataFim])

  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue)
  }

  const handleChangeNoMes = () => {
    const inicioDoMes = moment().startOf('month');
    const hoje = moment();

    setDataInicio(inicioDoMes);
    setDataFim(hoje);
  }

  const handleChangeOntem = () => {
    const ontem = moment().subtract("1", "days");

    setDataInicio(ontem);
    setDataFim(ontem);
  }

  const handleChangeHoje = () => {
    const hoje = moment();

    setDataInicio(hoje);
    setDataFim(hoje);
  }

  const handleChange7Dias = () => {
    const hoje = moment();
    const semanaPassada = moment().subtract("7", "days");

    setDataInicio(semanaPassada);
    setDataFim(hoje);

  }

  const handleChange30Dias = () => {
    const hoje = moment();
    const mesPassado = moment().subtract("30", "days");

    setDataInicio(mesPassado);
    setDataFim(hoje);

  }

  const handleChange1Ano = () => {
    const hoje = moment();
    const anoPassado = moment().subtract("12", "months");

    setDataInicio(anoPassado);
    setDataFim(hoje);
  }

  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue)
  }

  const handleChangeTab = (newValue) => {
    setTab(newValue)
  }

  const getColor = () => {

    var diff = moment().diff(moment(ultimaVendaImportada, 'DD/MM/YYYY'), 'days')
    if (diff > 1) {
      return palette.error.main
    }
    else {
      return palette.text.primary
    }

  }

  const handleClick = () => {

    if (dataInicio && dataFim) {

      if (idLoja !== '') {

        let filterModel = {
          IdLoja: idLoja,
          DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
          DataFim: moment(dataFim).format("YYYY-MM-DD")
        };

        dispatch(dashboardAction.getDashboardLoja(filterModel))
      }
      else {

        let filterModel = {
          LojaId: 0,
          DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
          DataFim: moment(dataFim).format("YYYY-MM-DD")
        };

        dispatch(dashboardAction.getDashboardLoja(filterModel))
      }

    }

  }

  return (
    <Page className={classes.root} title="Painel de Dados - Fidelidade">

      <Grid alignItems="flex-end" container spacing={3}>
        <Grid item>
          <DatePicker
            autoOk
            label="Início"
            clearable
            value={dataInicio}
            onChange={handleChangeDataInicio}
            disabled={isLoading}
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item>
          <DatePicker
            autoOk
            label="Fim"
            clearable
            value={dataFim}
            onChange={handleChangeDataFim}
            disabled={isLoading}
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="HOJE" onClick={handleChangeHoje} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="ONTEM" onClick={handleChangeOntem} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="7 DIAS" onClick={handleChange7Dias} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="NO MÊS" onClick={handleChangeNoMes} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="30 DIAS" onClick={handleChange30Dias} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="1 ANO" onClick={handleChange1Ano} disabled={isLoading} />
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClick}
            color="primary"
            disabled={isLoading}
          >
            Pesquisar
          </Button>
        </Grid>
      </Grid>

      <Grid alignItems="space-between" container spacing={1} style={{ marginTop: 10 }}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">Última atualização:</Typography>
        </Grid>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline" style={{ color: getColor() }}>{(ultimaVendaImportada != null && ultimaVendaImportada != '' ? ultimaVendaImportada : '')}</Typography>
        </Grid>
      </Grid>

      <Grid alignItems="flex-end" container spacing={3} style={{ marginBottom: 10 }}>
        <Grid item>
          <Chip className={classes.chips} label="FIDELIZAÇÕES/TROCAS" icon={<SwapHorizTwoToneIcon />} onClick={() => handleChangeTab(0)} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="CLIENTES" icon={<PeopleOutlineTwoToneIcon />} onClick={() => handleChangeTab(1)} />
        </Grid>
      </Grid>

      {!isEmpty(mensagens) &&
        undefined !== mensagens &&
        mensagens.length &&
        mensagens.map((row, index) => (
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <Alert severity={(row.tipo == 'U' ? "error" : (row.tipo == 'A' ? "warning" : (row.tipo == 'I' ? "info" : "error")))}>
                <AlertTitle><strong>{row.titulo}</strong></AlertTitle>
                {row.textoMensagem}
              </Alert>
              <br />
            </Grid>
          </Grid>
        ))
      }

      {(tab == 0 ?

        <Grid container spacing={4}>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <CuponsChartCard />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <VendasChartCard />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <TrocasChartCard />
          </Grid>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <FidelizacoesTrocasChartCard />
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <TopProdutosFidelizadosChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <TopProdutosTrocadosChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <FidelizacoesTiposChartCard />
          </Grid>
        </Grid>

        :

        <Grid container spacing={4}>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ClientesChartCard />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <NovosClientesChartCard />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ClientesRecorrentesChartCard />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ClientesNaoRecorrentesChartCard />
          </Grid>

          <Grid item xl={3} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <TopClientesChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={3} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <ClientesAniversariantesChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <GraficoClientesSexoChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <GraficoClientesFaixaEtariaChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {(dataInicio && dataFim ?
              <GraficoClientesRecorrenciaDiasChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} />
              :
              null
            )}
          </Grid>
        </Grid>

      )}

    </Page>
  )
}

export default Dashboard
