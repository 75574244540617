import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Skeleton } from "@material-ui/lab";
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import { cupomProdutoAction } from '../../_actions'
import DialogActions from '@material-ui/core/DialogActions';
import { StickyContainer, Sticky } from 'react-sticky';
import moment from 'moment'
import TipoFidelizacao from '../../utils/enums/tipoFidelizacao';

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const ClienteFidelizacaoModal = ({ modal, toggle, idFidelizacao, pontuacao }, props) => {

  const dispatch = useDispatch()
  const [isCampanha, setIsCampanha] = useState(false);
  const [chaveCupom, setChaveCupom] = useState('');
  const [dataHora, setDataHora] = useState('');
  const [tipo, setTipo] = useState('');

  const isLoadingFidelizacaoCliente = useSelector(
    (state) => state.cupomProduto.isLoading
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { cupomProduto } = useSelector(
    (state) => state.cupomProduto
  )

  useEffect(() => {

    if (idFidelizacao != 0) {
      dispatch(cupomProdutoAction.clear());
      dispatch(cupomProdutoAction.getFidelizacaoProdutos(idFidelizacao, true));

    } else {
      dispatch(cupomProdutoAction.clear());
    }

  }, [idFidelizacao])

  useEffect(() => {

    if (idFidelizacao != 0) {
      dispatch(cupomProdutoAction.clear());
      dispatch(cupomProdutoAction.getFidelizacaoProdutos(idFidelizacao, true));
    } else {
      dispatch(cupomProdutoAction.clear());
    }
  }, [])

  useEffect(() => {
    //se tiver idFidelizacao e dentro da pontuação também tiver, pesquisa pela chave do cupom
    //para exibir no modal

    if (idFidelizacao != 0 && pontuacao.find(c => c.idFidelizacao != null)) {
      var fidelizacao = pontuacao.find(f => f.idFidelizacao == idFidelizacao);

      if (fidelizacao && fidelizacao != null) {
        setChaveCupom(fidelizacao.fidelizacao.cupom.chave);
        setDataHora(moment(fidelizacao.fidelizacao.cupom.dataHora).format('DD/MM/YYYY HH:mm'));
        setTipo(fidelizacao.fidelizacao.tipo ? Object.values(TipoFidelizacao).at(fidelizacao.fidelizacao.tipo) : 'Não informado')
      }

      setIsCampanha(false)
    } else {
      setIsCampanha(true)
      setChaveCupom('')
      setDataHora('')
      setTipo('')
    }

  }, [idFidelizacao])

  return (
    <Dialog
      open={modal}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>
        <Grid alignItems="flex-start" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              PRODUTOS DA PONTUAÇÃO
            </Typography>
            {
              !isCampanha ? (
                <>
                  <Typography style={{ fontSize: "11px", textAlign: "left", marginTop: 3 }}>
                    Chave Cupom: {chaveCupom != '' ? chaveCupom : ''}
                  </Typography>
                  <Typography style={{ fontSize: "11px", textAlign: "left", marginTop: 3 }}>
                    Data do Cupom: {dataHora != '' ? dataHora : ''}
                  </Typography>
                  <Typography style={{ fontSize: "11px", textAlign: "left", marginTop: 3 }}>
                    Tipo: {tipo != '' ? tipo : ''}
                  </Typography>
                </>
              ) : null
            }
          </Grid>
          <AccessControl
            rule={'clientes.list'}
            yes={() => (
              <Grid item>
                <Grid item xs={6} align="right">
                  <Button
                    color="default"
                    variant="contained"
                    endIcon={<CloseIcon />}
                    onClick={toggle}
                  >
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <TableContainer>
              <Table id="tableResumo">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cupomProduto && undefined != cupomProduto && cupomProduto.length > 0
                    ? cupomProduto.map((n) => {
                      return (
                        <TableRow hover key={(n ? n.id : 0)}>
                          <TableCell component="th" scope="row" align="left" style={{ backgroundColor: "#FAFAFA" }}>
                            {n.produto ? n.produto?.nome : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {n.quantidade ? n.quantidade : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {n.valor ? 'R$ ' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : isLoadingFidelizacaoCliente ?
                      Array.apply(null, { length: 5 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                      :
                      <TableCell rowSpan={2} colSpan={3} align="center">Não há cupons vinculados a essa fidelização</TableCell>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StickyContainer>
          <Sticky relative={false} isSticky={true}>
            {({ style }) => (
              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }} spacing={3}>

              </Grid>
            )}
          </Sticky>
        </StickyContainer>
      </DialogActions>
    </Dialog>
  );
}
