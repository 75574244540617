import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { totvsInsumoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination 
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContentInsumo from '../TitleContentInsumo'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Insumo = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { totvsInsumo } = props.totvsInsumo
  const { totalRegistros } = props.totvsInsumo
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.totvsInsumo);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc'
    };

    dispatch(totvsInsumoAction.getTotvsInsumo(filterModel, true));
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Insumo?',
      description: 'Essa operação é irreversível',
      disabled: props.totvsInsumo.isLoading,
      confirmationText: props.totvsInsumo.isLoading ? 'Excluindo insumo...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(totvsInsumoAction.deleteTotvsInsumoById(id))
    })
  }

  const handleChange = prop => event => {
    dispatch(totvsInsumoAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(totvsInsumoAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.totvsInsumo.page = 0;
    dispatch(totvsInsumoAction.onChangeProps('term', fakeEvent))
  }

  return (
    <Page className={classes.root} title="Insumos">
      <AccessControl
        rule={'totvsInsumos'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== totvsInsumo && totvsInsumo.length > 0 ? (
              <TitleContentInsumo
                rule={'totvsInsumos.create'}
                length={totvsInsumo.length}
                subTitle={'GERÊNCIA DE INSUMOS'}
                title={'Lista de Insumos'}
                href={'/insumo'}
              />
            ) : (
                <TitleContentInsumo
                rule={'totvsInsumos.create'}
                length={0}
                subTitle={'GERÊNCIA DE INSUMOS'}
                title={'Lista de Insumos'}
                href={'/insumo'}
              />
            )}
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== totvsInsumo && totvsInsumo.length
                    ? totvsInsumo.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.nome}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'totvsInsumos.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/insumo/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Editar'}>
                                      <Tooltip >
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'totvsInsumos.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Insumo.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    totvsInsumo: state.totvsInsumo
  }
}
const connectedInsumoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Insumo))
)
export { connectedInsumoPage as Insumo }
