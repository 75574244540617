import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import {
  totvsInsumoAction,
  totvsInsumoConversaoAction,
  unidadeAction,
  categoriaCompraAction,
  subcategoriaCompraAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import HelpIcon from '@material-ui/icons/Help'
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useConfirm } from 'material-ui-confirm'
import { CompraClassificarInsumoModal } from '../Compras/compraClassificarInsumoModal.component'
import TipoInsumoPrioridade from '../../utils/enums/tipoInsumoPrioridade'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const InsumoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, totvsInsumo, className, totvsInsumoConversao, ...rest } = props
  const { match: { params } } = props;
  const { categoriaCompra } = props.categoriaCompra
  const { subcategoriaCompra } = props.subcategoriaCompra
  const formRef = React.createRef(null);

  const [modalClassificacao, setModalClassificacao] = useState(false)
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const unidade = useSelector((state) => state.unidade.unidade)

  useEffect(() => {
    dispatch(unidadeAction.getUnidadeSelect())
    dispatch(categoriaCompraAction.getCategoriaSelect())
    dispatch(subcategoriaCompraAction.getSubcategoriaSelect())

    if (params.id) {
      dispatch(totvsInsumoAction.getTotvsInsumoById(params.id))
    } else {
      dispatch(totvsInsumoAction.clear())
    }
  }, []);

  useEffect(() => {
    if (!modalClassificacao && params?.id && !totvsInsumoConversao.isLoading) {
      dispatch(totvsInsumoAction.clear())
      dispatch(totvsInsumoAction.getTotvsInsumoById(params?.id))
    }
  }, [modalClassificacao, totvsInsumoConversao.isLoading])

  useEffect(() => {

  }, [ready, props.totvsInsumo.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.totvsInsumo.nome,
      codigo: props.totvsInsumo.codigo,
      aproveitamento: parseFloat(props.totvsInsumo.aproveitamento),
      embalagem: parseFloat(props.totvsInsumo.embalagem),      
      idUnidade: (props.totvsInsumo.idUnidade != 0 ? props.totvsInsumo.idUnidade : null),
      revisado: props.totvsInsumo.revisado,
      contabilizado: props.totvsInsumo.contabilizado
    }

    if (params.id) {
      dispatch(totvsInsumoAction.editTotvsInsumoInfo(params.id, payload, true))
    } else {
      dispatch(totvsInsumoAction.createTotvsInsumo(payload))
    }

  };

  const handleEditConversao = (id) => {
    dispatch(totvsInsumoConversaoAction.getTotvsInsumoConversaoById(id))
    setModalClassificacao(true)
  }

  const handleOpenModal = () => {
    dispatch(totvsInsumoConversaoAction.clear())
    setModalClassificacao(true)
  }

  const handleDeleteConversao = (id) => {
    confirm({
      title: 'Você deseja desvincular esta conversão?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.totvsInsumo.isLoading,
      confirmationText: props.totvsInsumo.isLoading ? 'Excluindo conversão...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(totvsInsumoConversaoAction.deleteTotvsInsumoConversaoById(id, params.id))
    })
  }
   
  //const insumosConv = props.totvsInsumo.totvsInsumosConversoes;
  //const sortedData = [...insumosConv].sort((a, b) => a.prioridade - b.prioridade);

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Insumo'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Insumo'}</b>
      </Typography>
    )
  }

  function ConversoesText() {
    return (
      <Typography variant="h4">
        <b>{'Conversões vinculadas'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SegHeaderConversoes() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
        <ConversoesText />
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleOpenModal()}
        >
          NOVO
        </Button>
      </Box>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Insumos - Editar Insumo'
          : 'Gerência de Insumos - Novo Insumo'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formInsumo"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Insumos"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >
                  <Grid container spacing={2}>
                    <Grid item lg={10} md={10} xl={10} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.totvsInsumo.nome || ''}
                        variant="outlined"
                        onInput={stringUtils.toInputUppercase}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="codigo"
                        label="Código *"
                        margin="normal"
                        onChange={handleChange('codigo')}
                        value={props.totvsInsumo.codigo || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idUnidade"
                        label="Unidade"
                        value={props.totvsInsumo.idUnidade || ''}
                        onChange={handleChange('idUnidade')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idUnidade"
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em>Unidade</em>
                        </MenuItem>
                        {!isEmpty(unidade) &&
                          undefined !== unidade &&
                          unidade.length &&
                          unidade.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>                    
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="aproveitamento"
                        label="Aproveitamento *"
                        margin="normal"
                        type="number"
                        onChange={handleChange('aproveitamento')}
                        value={props.totvsInsumo.aproveitamento}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        inputProps={{
                          min: 0,
                          step: 0.01
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={11} >
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="embalagem"
                        label="Embalagem"
                        margin="normal"
                        type="number"
                        onChange={handleChange('embalagem')}
                        value={props.totvsInsumo.embalagem}
                        variant="outlined"
                        inputProps={{
                          min: 0,
                          step: 1
                        }}
                      />
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title="Usado apenas em insumos compostos para calcular a proporção"
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.totvsInsumo.revisado}
                            onChange={handleChange('revisado')}
                            color="primary"
                          />
                        }
                        label="Revisado"
                      />
                      <Tooltip
                        title="Utilizado no Módulo de Custos. Insumos revisado passam a ser vistos pelos franqueados."
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.totvsInsumo.contabilizado}
                            onChange={handleChange('contabilizado')}
                            color="primary"
                          />
                        }
                        label="Contabilizado"
                      />
                      <Tooltip
                        title="Insumos contabilizados exibem Validade e Estoque nos Checklists de Operação"
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/insumos')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.totvsInsumo.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.totvsInsumo.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.totvsInsumo.isLoading ? 'Atualizando insumo...' : 'Atualizar') : (props.totvsInsumo.isLoading ? 'Salvando insumo...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
        {params.id &&
          <Grid item md={12} xs={12}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginTop: '12px' }}>
                <CardContent>
                  <CardHeader
                    title={<SegHeaderConversoes />}
                    subheader="Conversões vinculadas a esta unidade de insumo"
                  />
                  <Divider />
                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Campo</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Texto</TableCell>
                            <TableCell>Campo 2</TableCell>
                            <TableCell>Tipo 2</TableCell>
                            <TableCell>Texto 2</TableCell>
                            <TableCell>Conversão</TableCell>
                            <TableCell>Prioridade</TableCell>
                            <TableCell>Categoria</TableCell>
                            <TableCell>Subcategoria</TableCell>
                            <TableCell>Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            (props.totvsInsumo.totvsInsumosConversoes != null && props.totvsInsumo.totvsInsumosConversoes.length > 0 ?
                              props.totvsInsumo.totvsInsumosConversoes.map(n => {
                                return (
                                  (n.id !== '' ?
                                    <TableRow hover >
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.campo}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.tipo}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.texto ? n.texto : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.campo2 ? n.campo2 : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.tipo2 ? n.tipo2 : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.texto2 ? n.texto2 : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.conversao ? n.conversao : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.prioridade !== null ? Object.values(TipoInsumoPrioridade).at(n.prioridade - 1) : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.categoriaCompra ? n.categoriaCompra.nome : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}
                                        style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                        {n.subcategoriaCompra ? n.subcategoriaCompra.nome : ''}
                                      </TableCell>
                                      <TableCell lg={12} md={12} xl={12} xs={12}>
                                        <IconButton
                                          aria-label="Edit"
                                          component="a"
                                          onClick={() => handleEditConversao(n.id)}
                                          size="small"
                                        >
                                          <Tooltip title={'Editar'}>
                                            <Tooltip>
                                              <EditIcon />
                                            </Tooltip>
                                          </Tooltip>
                                        </IconButton>
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={() => handleDeleteConversao(n.id)}
                                          size="small"
                                        >
                                          <Tooltip title="Excluir">
                                            <DeleteIcon />
                                          </Tooltip>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    : null)
                                )
                              })
                              : <TableRow>
                                <TableCell colSpan={8}>
                                  <Typography align="center" variant="subtitle2">
                                    Nenhuma conversão vinculada até o momento
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
      </Grid>

      {modalClassificacao && <CompraClassificarInsumoModal toggle={() => setModalClassificacao(false)} modal={modalClassificacao} props={props} idTotvsInsumoConversao={totvsInsumoConversao.id || 0} totvsInsumoSelected={props.totvsInsumo} categorias={categoriaCompra} subcategorias={subcategoriaCompra} />}

    </Page >
  )
}

InsumoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedInsumoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(InsumoEdit))
)
export { connectedInsumoEditPage as InsumoEdit }
