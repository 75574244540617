import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Input,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { checklistAction, checklistTotvsInsumoUnidadeAction, categoriaInsumoAction, lojaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@material-ui/icons/Save'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { arrayMoveMutable } from 'array-move'
import DragHandleIcon from '@material-ui/icons/DragHandle'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  descricao: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tituloDelivery: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'column',
    flex: 1,
    marginTop: 10
  }
})

const ChecklistEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, checklist, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [, forceUpdate] = React.useState(0);

  const categorias = useSelector((state) => state.categoriaInsumo.categoriaInsumo)
  const lojas = useSelector((state) => state.loja.loja)

  const checklistsTotvsInsumosUnidadesSelector = useSelector((state) => state.checklistTotvsInsumoUnidade.checklistTotvsInsumoUnidade)
  const [checklistsTotvsInsumosUnidades, setChecklistsTotvsInsumosUnidades] = useState(checklistsTotvsInsumosUnidadesSelector);

  //const checklistsTotvsInsumosUnidades = useSelector(state => {
  //  // Each time this runs it returns a brand new object
  //  return state.checklistTotvsInsumoUnidade.checklistTotvsInsumoUnidade
  //})
  //const [checklistsTotvsInsumosUnidades, setChecklistsTotvsInsumosUnidades] = useStatWEe(checklistsTotvsInsumosUnidadesSelector);

  const SortableItem = sortableElement(({ value }) => {
    return (
      <TableRow hover key={value}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="Arrastar"
            component="a"
          >
            <Tooltip title="Arraste e solte">
              <Tooltip >
                <DragHandleIcon />
              </Tooltip>
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {value.fornecedores}
        </TableCell>
        <TableCell component="th" scope="row">
          {value.totvsInsumoUnidade.nome}
        </TableCell>
        <TableCell component="th" scope="row">
          <FormControlLabel
            control={
              <Switch
                checked={getIndisponivel(value.totvsInsumoUnidade.id)}
                onChange={(event) => handleChangeIndisponivel(event, 'indisponivel', value)}
                color="primary"
              />
            }
            label="Indisponível"
          />
        </TableCell>
      </TableRow>
    )
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Fornecedor Padrão</TableCell>
              <TableCell>Unidade de Insumo</TableCell>
              <TableCell>Indisponível</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
    )
  });

  useEffect(() => {

    //console.log('params', params)
    if (params.id) {
      dispatch(checklistAction.getChecklistById(params.id))
    } else {
      dispatch(checklistAction.clear())
      dispatch(checklistAction.clearChecklistsLojas())
      dispatch(checklistAction.clearChecklistsTotvsInsumosUnidades())
    }

    dispatch(categoriaInsumoAction.getCategoriaInsumoSelect())
    dispatch(lojaAction.getLojaSelect())
    dispatch(checklistTotvsInsumoUnidadeAction.clearAll())

  }, []);

  useEffect(() => {

  }, [props.checklist.isLoading])

  useEffect(() => {
    //console.log('props.checklist', props.checklist)
    if (props.checklist && props.checklist.idCategoriaInsumo > 0) {
      let filterModel = {
        IdCategoriaInsumo: props.checklist.idCategoriaInsumo,
        IdChecklist: (params.id || 0)
      };
      dispatch(checklistTotvsInsumoUnidadeAction.getChecklistTotvsInsumoUnidadeCategoriaInsumo(filterModel, true))
    }
    else {
      dispatch(checklistTotvsInsumoUnidadeAction.clearAll())
    }
  }, [props.checklist.idCategoriaInsumo]);

  useEffect(() => {
    if (checklistsTotvsInsumosUnidadesSelector && checklistsTotvsInsumosUnidadesSelector.length > 0) {
      setChecklistsTotvsInsumosUnidades(checklistsTotvsInsumosUnidadesSelector)
    }
  }, [checklistsTotvsInsumosUnidadesSelector])

  useEffect(() => {
    //console.log('checklistsTotvsInsumosUnidades 1', checklistsTotvsInsumosUnidades)
  }, [JSON.stringify(checklistsTotvsInsumosUnidades)]);

  const handleChange = (prop) => (event) => {
    dispatch(checklistAction.onChangeProps(prop, event))
  }

  const handleSelectChangeState = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      descricao: props.checklist.descricao,
      idCategoriaInsumo: props.checklist.idCategoriaInsumo,
      tipo: props.checklist.tipo,
      checklistsLojas: props.checklist.checklistsLojas,
      checklistsTotvsInsumosUnidades: checklistsTotvsInsumosUnidades
    }

    if (params.id) {
      dispatch(checklistAction.editChecklistInfo(params.id, payload, true))
    } else {
      dispatch(checklistAction.createChecklist(payload))
    }
  };

  const handleAddOnList = e => {

    if (props.checklist.checklistsLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.checklist.checklistsLojas.push({ 'idLoja': idLoja, 'idChecklist': props.checklist.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos uma loja!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {

    props.checklist.checklistsLojas = props.checklist.checklistsLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(checklistTotvsInsumoUnidadeAction.onChangeProps('checklistsTotvsInsumosUnidades', arrayMoveMutable(checklistsTotvsInsumosUnidades, oldIndex, newIndex)))
    //setChecklistsTotvsInsumosUnidades(arrayMoveMutable(checklistsTotvsInsumosUnidades, oldIndex, newIndex))
  };

  function excludeFilter(obj) {
    if (props.checklist.checklistsLojas && props.checklist.checklistsLojas.length > 0) {
      if (props.checklist.checklistsLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  const handleChangeIndisponivel = (event, prop, n) => {

    //var checklistsTotvsInsumosUnidades = props.checklist.checklistsTotvsInsumosUnidades;

    if (checklistsTotvsInsumosUnidades && checklistsTotvsInsumosUnidades.length > 0) {

      var index = checklistsTotvsInsumosUnidades.findIndex(x => x.idTotvsInsumoUnidade == n.idTotvsInsumoUnidade);
      if (index >= 0) {

        const novosChecklistsTotvsInsumosUnidades = checklistsTotvsInsumosUnidades.map(x =>
          x.idTotvsInsumoUnidade == n.idTotvsInsumoUnidade ? { ...x, indisponivel: event.target.checked } : x
        );

        //setChecklistsTotvsInsumosUnidades(novosChecklistsTotvsInsumosUnidades)
        dispatch(checklistTotvsInsumoUnidadeAction.onChangeProps('checklistsTotvsInsumosUnidades', novosChecklistsTotvsInsumosUnidades))

      } else {

        let payload = {
          id: 0,
          idChecklist: (params.id ? params.id : 0),
          idTotvsInsumoUnidade: n.idTotvsInsumoUnidade,
          indisponivel: event.target.checked
        }

        checklistsTotvsInsumosUnidades.push(payload);
        //setChecklistsTotvsInsumosUnidades(checklistsTotvsInsumosUnidades)
        dispatch(checklistTotvsInsumoUnidadeAction.onChangeProps('checklistsTotvsInsumosUnidades', checklistsTotvsInsumosUnidades))

      }
    } else {

      let payload = {
        id: 0,
        idChecklist: (params.id ? params.id : 0),
        idTotvsInsumoUnidade: n.idTotvsInsumoUnidade,
        indisponivel: event.target.checked
      }

      checklistsTotvsInsumosUnidades.push(payload);
      //setChecklistsTotvsInsumosUnidades(checklistsTotvsInsumosUnidades)
      dispatch(checklistTotvsInsumoUnidadeAction.onChangeProps('checklistsTotvsInsumosUnidades', checklistsTotvsInsumosUnidades))

    }

  };

  function getIndisponivel(codigo) {
    if (checklistsTotvsInsumosUnidades && checklistsTotvsInsumosUnidades.length > 0) {
      const produto = checklistsTotvsInsumosUnidades.find(p => p.idTotvsInsumoUnidade == codigo);
      if (produto) {
        return produto.indisponivel || false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Planilha de Contagem'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Planilha de Contagem'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Planilhas de Contagem - Editar Planilha de Contagem'
          : 'Gerência de Planilhas de Contagem - Nova Planilha de Contagem'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formChecklist"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Planilhas de Contagem"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>

                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição *"
                        onInput={stringUtils.toInputUppercase}
                        margin="normal"
                        onChange={handleChange('descricao')}
                        value={props.checklist.descricao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                  </Grid>

                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <SelectValidator
                      className={classes.textField}
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="idCategoriaInsumo"
                      label="Categoria *"
                      value={props.checklist.idCategoriaInsumo}
                      onChange={handleChange('idCategoriaInsumo')}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="idCategoriaInsumo"
                      variant="outlined"
                      isDisabled={(params.id != 0 ? true : false)}
                    >
                      <MenuItem disabled value="">
                        <em>Categoria *</em>
                      </MenuItem>
                      {!isEmpty(categorias) &&
                        undefined !== categorias &&
                        categorias.length &&
                        categorias.map((row, index) => (
                          <MenuItem key={index} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>

                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <SelectValidator
                      className={classes.textField}
                      label="Tipo"
                      labelId="tipo"
                      value={props.checklist.tipo || ''}
                      onChange={handleChange('tipo')}
                      name="tipo"
                      fullWidth
                      inputProps={{
                        name: "Tipo",
                        id: "tipo",
                        shrink: true
                      }}
                      input={<Input id="tipo" />}
                      margin="normal"
                      variant="outlined"
                      id="tipo"
                    >
                      <MenuItem value={'N'}>NORMAL</MenuItem>
                      <MenuItem value={'D'}>DIÁRIO</MenuItem>
                    </SelectValidator>
                  </Grid>

                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>

                  <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>

                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >

                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idLoja"
                        label="Lojas vinculadas *"
                        value={idLoja}
                        onChange={handleSelectChangeState('idLoja')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idLoja"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Loja *</em>
                        </MenuItem>
                        {!isEmpty(lojas) &&
                          undefined !== lojas &&
                          lojas.length &&
                          lojas.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          {(props.checklist && props.checklist.checklistsLojas && props.checklist.checklistsLojas.length > 0 ?
                            props.checklist.checklistsLojas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button color="default" onClick={() => handleRemoveOnList(n.idLoja)} key={'button' + n.id}>
                                    <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                      {(n.loja ? n.loja.nome : n.idLoja)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                  : null)
                              );
                            })
                            :
                            null
                          )}
                        </Grid>
                      </div>
                    </Grid>

                  </Grid>

                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>

                      {(checklistsTotvsInsumosUnidades && checklistsTotvsInsumosUnidades.length > 0 ?
                        <SortableContainer onSortEnd={onSortEnd}>
                          {checklistsTotvsInsumosUnidades.map((n, index) => (
                            <SortableItem key={`item-${n.nome}`} index={index} value={n} />
                          ))}
                        </SortableContainer>
                        :
                        null
                      )}

                      {/*
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Unidade de Insumo</TableCell>
                              <TableCell align="right">Ordem</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {undefined !== totvsInsumosUnidades && totvsInsumosUnidades.length
                              ? totvsInsumosUnidades.map((n) => {
                                return (
                                  <TableRow hover key={(n.id || 0)}>
                                    <TableCell component="th" scope="row">
                                      <Link color="inherit" href={"/produtoCompra/" + (n.id || 0)} target="_blank">
                                        {(n.nome.toUpperCase() || '')}
                                      </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Input
                                        id="valor"
                                        label="Valor"
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        pattern="^\d*(\.\d{0,2})?$"
                                        value={getOrdem(n.id)}
                                        onChange={(event) => handleChangeOrdem(event, 'ordem', n.id)}
                                        fullWidth
                                        inputProps={{
                                          min: 0,
                                          style: { textAlign: "right" },
                                          pattern: "^\d*(\.\d{0,2})?$", step: "0.01"
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      */}

                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>

                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/checklists')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.checklist.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.checklist.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.checklist.isLoading ? 'Atualizando planilha de contagem...' : 'Atualizar') : (props.checklist.isLoading ? 'Salvando planilha de contagem...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  );
}

ChecklistEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedChecklistEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ChecklistEdit))
)
export { connectedChecklistEditPage as ChecklistEdit };
