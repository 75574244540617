import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { categoriaCompraAction, subcategoriaCompraAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page, PaginationActions } from 'components'
import { AccessControl } from '../../components/Utils/AccessControl'
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useConfirm } from 'material-ui-confirm'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const CategoriaCompraEdit = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const formRef = React.createRef(null);
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { subcategoriaCompra, totalRegistros } = props.subcategoriaCompra
  const { page, limit, term, direction, order } = useSelector(state => state.subcategoriaCompra);

  const [open, setOpen] = useState(false);
  const [subcategoriaId, setSubcategoriaId] = useState(0);
  const [subcategoriaNome, setSubcategoriaNome] = useState('');

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  const fetchData = () => {
    if (params.id) {
      dispatch(categoriaCompraAction.getCategoriaById(params.id))
      dispatch(subcategoriaCompraAction.getSubcategoriasByCategoriaId(params.id))
    } else {
      dispatch(categoriaCompraAction.clear())
      dispatch(subcategoriaCompraAction.clear())
    }
  };

  const handleChange = (prop) => (event) => {
    dispatch(categoriaCompraAction.onChangeProps(prop, event))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Subcategoria?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.categoriaCompra.isLoading,
      confirmationText: props.categoriaCompra.isLoading ? 'Excluindo subcategoria...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(subcategoriaCompraAction.deleteSubcategoriaById(id, props.categoriaCompra.id))
    })
  }

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(subcategoriaCompraAction.onChangeProps('page', fakeEvent))
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.categoriaCompra.nome,
      cpv: props.categoriaCompra.cpv
    }

    if (params.id) {
      dispatch(categoriaCompraAction.editCategoriaInfo(params.id, payload, true))
    } else {
      dispatch(categoriaCompraAction.createCategoria(payload))
    }
  };

  const handleSubmitSubcategoria = (event) => {
    let payload = {
      id: subcategoriaId,
      idCategoriaCompra: params.id,
      nome: subcategoriaNome,
    }

    if (subcategoriaId) {
      dispatch(subcategoriaCompraAction.editSubcategoriaInfo(subcategoriaId, payload, true))
    } else {
      dispatch(subcategoriaCompraAction.createSubcategoria(payload))
    }

    setSubcategoriaNome("")
    handleClose()
  };

  const handleChangeSubcategoriaNome = (event) => {
    setSubcategoriaNome(event.target.value)
  }

  const editSubcategoria = (event, subcategoria) => {
    setSubcategoriaId(subcategoria.id)
    setSubcategoriaNome(subcategoria.nome)
    handleClickOpen(event)
  };

  const handleClickOpen = (event) => {
    event.preventDefault()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubcategoriaId(0)
  };

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Categoria de Compra'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Categoria de Compra'}</b>
      </Typography>
    )
  }

  function SubcategoriasText() {
    return (
      <Typography variant="h4">
        <b>{'Subcategorias'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SegHeaderSubcategorias() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
        <SubcategoriasText />
        <Button
          color="primary"
          variant="contained"
          disabled={!params.id}
          onClick={(event) => handleClickOpen(event)}
        >
          NOVO
        </Button>
      </Box>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Categoria - Editar Categoria de Compra'
          : 'Gerência de Categoria - Nova Categoria de Compra'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formCategoriaCompra"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Categoria de Compras"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2} alignItems="center" >
                    <Grid item lg={9} md={9} xl={9} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Nome *"
                        value={props.categoriaCompra.nome}
                        onChange={handleChange('nome')}
                        id="nome"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.categoriaCompra.cpv}
                            onChange={handleChange('cpv')}
                            color="primary"
                          />
                        }
                        label="Considerar categoria nos indicadores de custo"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/categoriasCompras')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.categoriaCompra.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.categoriaCompra.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.categoriaCompra.isLoading ? 'Atualizando categoria...' : 'Atualizar') : (props.categoriaCompra.isLoading ? 'Salvando categoria...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
            <br />
            <Card style={{ marginTop: '12px' }}>
              <CardContent>
                <CardHeader
                  title={<SegHeaderSubcategorias />}
                  subheader="Subcategorias de compras"
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell align="right">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== subcategoriaCompra && subcategoriaCompra.length
                          ? subcategoriaCompra.map((sc) => {

                            return (
                              <TableRow hover key={sc.id}>
                                <TableCell component="th" scope="row">
                                  {sc.nome}
                                </TableCell>
                                <TableCell align="right">
                                  <AccessControl
                                    rule={'subcategoriasCompras.edit'} //permissão necessária para acessar conteúdo
                                    yes={() => (
                                      <IconButton
                                        aria-label="Edit"
                                        component="a"
                                        onClick={(event) => editSubcategoria(event, sc)}
                                        size="small"
                                      >
                                        <Tooltip title={'Editar'}>
                                          <Tooltip>
                                            <EditIcon />
                                          </Tooltip>
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                  <AccessControl
                                    rule={'subcategoriasCompras.delete'} //permissão necessária para acessar conteúdo
                                    yes={() => (
                                      <IconButton
                                        aria-label="Delete"
                                        onClick={() => handleClick(sc.id)}
                                        size="small"
                                      >
                                        <Tooltip title="Excluir">
                                          <DeleteIcon />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChange('limit')}
                    ActionsComponent={PaginationActions}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <ValidatorForm
          className={classes.form}
          ref={formRef}
          id="formCategoriaCompra"
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogContent>
            <DialogContentText>{(subcategoriaId ? 'Editar' : 'Adicionar') + ' Subcategoria'}</DialogContentText>
            <TextValidator
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Nome *"
              onChange={handleChangeSubcategoriaNome}
              value={subcategoriaNome || ''}
              id="subcategoriaNome"
              validators={['required']}
              errorMessages={['Campo obrigatório!']}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={handleClose}
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              onClick={handleSubmitSubcategoria}
              variant="contained"
            >
              {subcategoriaId ? 'Editar' : 'Adicionar' }
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Page>
  )
}

CategoriaCompraEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedCategoriaCompraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CategoriaCompraEdit))
)
export { connectedCategoriaCompraEditPage as CategoriaCompraEdit }
