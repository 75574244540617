import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Avatar, Box, Tooltip } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RemoveIcon from '@material-ui/icons/Remove'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone'
import { AccessControl } from '../../../../components/Utils/AccessControl'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const VendasChartCard = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const percentualParticipacao = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.percentualParticipacao : undefined) : undefined))
  const rankingpercentualParticipacao = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.rankingpercentualParticipacao : undefined) : undefined))
  const totalLojas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalLojas : undefined) : undefined))
  const metasPercentualParticipacao = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.metasPercentualParticipacao : undefined) : undefined))

  const { isLoading } = useSelector((state) => state.dashboard)

  const handleVariant = () => {
    let isMetaHigher = metasPercentualParticipacao > percentualParticipacao;
    let isMetaEqual = metasPercentualParticipacao == percentualParticipacao;

    let sub = metasPercentualParticipacao - percentualParticipacao;
    let variant = (isMetaHigher ? sub : sub * -1).toFixed(2).replace(".", ",");

    let variantObj = { title: '', icon: null }

    if (isMetaHigher) {
      variantObj.title = `${variant} Pontos Percentuais abaixo da meta da loja.`
      variantObj.icon = <ArrowDropDownIcon style={{ color: '#880000' }} />
    } else {
      variantObj.title = `${variant} Pontos Percentuais acima da meta da loja.`
      variantObj.icon = <ArrowDropUpIcon style={{ color: '#008800' }} />
    }

    if (isMetaEqual) {
      variantObj.title = 'Equivalente a meta da loja'
      variantObj.icon = <RemoveIcon style={{ color: '#777' }} />
    }

    return (
      <Tooltip arrow title={variantObj.title}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" style={{ marginLeft: '20px' }}>
          {variantObj.icon}

          <Typography variant="h5" style={{ color: '#777' }}>{isLoading ? <Skeleton /> : variant + ' p.p.'}</Typography>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item style={{ flex: 1, marginRight: '20px' }}>
            <Box display="flex" flexDirection="row" alignItems="center"  justifyContent="flex-start">
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                PARTICIPAÇÃO FIDELIZAÇÕES
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
              <Typography variant="h3">
                {isLoading ? (
                  <Skeleton />
                ) :
                  Intl.NumberFormat('pt-BR', {
                    currency: 'BRL'
                  }).format((isNaN(percentualParticipacao) ? 0 : percentualParticipacao)) + '%'
                }
              </Typography>
              <Typography variant="h3">{isLoading ? <Skeleton /> : ''}</Typography>
              {handleVariant()}
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">
              <AccessControl
                rule={'relatorios'} //permissão necessária para acessar conteúdo
                yes={() => (
                  <>
                    <Typography variant="body2">META</Typography>
                    <Typography variant="h3">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        Intl.NumberFormat('pt-BR', {
                          currency: 'BRL'
                        }).format((isNaN(metasPercentualParticipacao) ? 0 : metasPercentualParticipacao)) + '%'
                      )}
                    </Typography>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LocalAtmTwoToneIcon className={classes.icon} />
            </Avatar>
            <Typography variant="h5" style={{ marginTop: 5 }}>
              {isLoading ? (
                <Skeleton />
              ) : (
                  (rankingpercentualParticipacao != null ? rankingpercentualParticipacao + 'º / ' + totalLojas : '')
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

VendasChartCard.propTypes = {
  className: PropTypes.string
}

export default VendasChartCard
