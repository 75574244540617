import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import { options } from './options'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const FidelizacoesTiposChartCard = (props) => {
  const { className, ...rest } = props
  const graficoFidelizacoesTipos = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoFidelizacoesTipos : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const generateData = () => {
    if (graficoFidelizacoesTipos) {
      const data = {
        labels: graficoFidelizacoesTipos.options, //labels
        datasets: [
          {
            label: 'Número de fidelizações por tipo',
            data: graficoFidelizacoesTipos.series[0],  //dados
            backgroundColor: [
              palette.error.main,
              '#2196F3',
              palette.primary.light,
              '#4CAF50'
            ],
            borderColor: [
              palette.error.main,
              '#2196F3',
              palette.primary.light,
              '#4CAF50'
            ],
            borderWidth: 2
          },
        ],
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="TIPOS DE FIDELIZAÇÕES"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Pie data={generateData} options={options} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

FidelizacoesTiposChartCard.propTypes = {
  className: PropTypes.string
}

export default FidelizacoesTiposChartCard
