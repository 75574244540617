import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { fidelizacaoAction } from '_actions'
import { stringUtils } from 'utils'

export const clienteAction = {
  getCliente,
  getClienteSelect,
  getClienteById,
  getFidelizacoesByDias,
  getClientesByCampanha,
  getClienteArquivo,
  onChangeProps,
  editClienteInfo,
  changeDetailsCliente,
  createCliente,
  deleteClienteById,
  getClienteByEstabelecimento,
  emailVerification,
  requestEmailVerification,
  requestPasswordReset,
  passwordReset,
  openModalRequestPasswordReset,
  verifyClienteTelefone,
  verifyClienteEmail,
  openModalClientesTransferirPontos,
  openModalClientesAdicionarPontosManualmente,
  openModalClientesAdicionarTrocasManualmente,
  transferPontosCliente,
  clear,
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getCliente(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'clientes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeClientesList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getClienteArquivo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'clientes/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeClientesList(response.data.items, response.data.totalRows))
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "clientes.csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
        dispatch(clear())
        getCliente()
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getClienteSelect() {
  return (dispatch) => {
    let apiEndpoint = 'clientes/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeClientesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function requestEmailVerification(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'clientes/request-email-verification'
    crudService
      .post(apiEndpoint, payload)
      .then(() => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_EMAIL_VERIFICATION_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_FAILURE', error }
  }
}

function verifyClienteTelefone(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'clientesTelefones/verificar/admin/' + id
    crudService
      .post(apiEndpoint)
      .then(() => {
        toast.success('Feito! Telefone verificado com sucesso! 😎')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
}

function verifyClienteEmail(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'clientes/verificarEmail/admin/' + id
    crudService
      .post(apiEndpoint)
      .then(() => {
        toast.success('Feito! E-mail verificado com sucesso! 😎')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
}

function request(id) {
  return { type: 'EDIT_CLIENTE_REQUEST', id }
}

function failure(error) {
  return { type: 'EDIT_CLIENTE_FAILURE', error }
}

function createCliente(payload, cliente) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'clientes'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Cadastro realizado com sucesso! 😎')
        dispatch(clear())
        history.push('/clientes')
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'CREATE_CLIENTE_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_CLIENTE_FAILURE', error }
  }
}

function transferPontosCliente(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'clientes/transferir'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Pontos transferidos com sucesso! 😎')

        let filterModel = {
          IdCliente: payload.idClienteOrigem
        };

        dispatch(fidelizacaoAction.getFidelizacao(filterModel, true))
        dispatch(getClienteById(payload.idClienteOrigem))
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(failure(err))
      })
  }
}

export function openModalClientesTransferirPontos(open) {
  return {
    type: 'OPEN_MODAL_CLIENTES_TRANSFERIR_PONTOS',
    open: open
  }
}

export function openModalClientesAdicionarPontosManualmente(open) {
  return {
    type: 'OPEN_MODAL_CLIENTES_ADICIONAR_PONTOS_MANUALMENTE',
    open: open
  }
}

export function openModalClientesAdicionarTrocasManualmente(open) {
  return {
    type: 'OPEN_MODAL_CLIENTES_ADICIONAR_TROCAS_MANUALMENTE',
    open: open
  }
}

export function clear() {
  return {
    type: 'CLIENTE_CLEAR'
  }
}

function requestPasswordReset(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(email))
    let apiEndpoint = 'clientes/request-reset-password'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        if (response && response.request.status === 200) {
          toast.success('E-mail de recuperação enviado! 😎')
        } else if (response && response.request.status === 404) {
          toast.warn('Não existe nenhum cliente com este e-mail')
        } else {
          toast.error('Erro ao recuperar senha do cliente')
        }
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(failure(err))
        toast.error('Erro ao recuperar senha do cliente')
      })
  }
  function request(payload) {
    return { type: 'REQUEST_RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_RESET_PASSWORD_FAILURE', error }
  }
}

function passwordReset(payload, token) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'clientes/reset-password'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(success(response.data))
          toast.success('Senha redefinida com sucesso! 😎')
          history.push('/')
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum cliente com o e-mail informado!')
        } else {
          toast.error('Erro ao recuperar senha do cliente')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
  function request(payload) {
    return { type: 'RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'RESET_PASSWORD_FAILURE', error }
  }
}

function emailVerification(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'clientes/email-verification'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          toast.success('E-mail verificado com sucesso! 😎')
          history.push('/')
          dispatch(success())
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum cliente com o e-mail informado!')
        } else {
          toast.error('Erro ao verificar e-mail do cliente')
        }
      })
      .catch(() => {
        toast.error('Erro ao verificar e-mail do cliente')
      })
  }

  function success() {
    return {
      type: 'VERIFICATION_CLIENTE_SUCCESS'
    }
  }
}

function editClienteInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'clientes/' + id
    crudService
      .put(apiEndpoint, payload)
      .then(() => {
        dispatch(success(payload))
        if (redirect) {
          history.push('/clientes')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'EDIT_CLIENTE_REQUEST', payload }
  }

  function success(cliente) {
    return {
      type: 'EDIT_CLIENTE_SUCCESS',
      cliente: cliente
    }
  }

  function failure(error) {
    return { type: 'EDIT_CLIENTE_FAILURE', error }
  }
}

function getClienteById(id) {
  return (dispatch) => {
    let apiEndpoint = 'clientes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editClientesDetails(response.data))
    })
  }
}

function getFidelizacoesByDias(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'clientes/fidelizacoesPeriodo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeClientesList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getClientesByCampanha(filterModel, idCampanha, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = ('clientes/campanha/' + idCampanha) + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeClientesList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getClienteByEstabelecimento(id) {
  return (dispatch) => {
    let apiEndpoint = 'clientes/estabelecimento/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeClientesList(response.data))
    })
  }
}
export function openModalRequestPasswordReset(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_PASSWORD_RESET',
    open: open
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteClienteById(id) {
  return (dispatch) => {
    let apiEndpoint = 'clientes/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteClientesDetails())
      //dispatch(
      //  clienteAction.getClienteByEstabelecimento(
      //    response.data.estabelecimentoId
      //  )
      //)
      dispatch(getCliente(filterModel))
    })
  }
}
function changeDetailsCliente(cliente) {
  return (dispatch) => {
    if (cliente) {
      dispatch(onChangeDetailsCliente(cliente))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsCliente(cliente) {
  return [
    {
      type: 'CHANGE_DETAILS_CLIENTE',
      id: cliente.id,
      nome: cliente.nome
    }
  ]
}

export function changeClientesList(cliente, totalRows) {
  return {
    type: 'FETCHED_ALL_CLIENTE',
    cliente: cliente,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'CLIENTE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editClientesDetails(cliente) {
  return {
    type: 'CLIENTE_DETAIL',
    id: cliente.id,
    usuario: cliente.usuario,
    senha: cliente.senha,
    isLoading: cliente.isLoading,
    idEstado: cliente.idEstado,
    idCidade: cliente.idCidade,
    endereco: cliente.endereco,
    cep: cliente.cep,
    caminhoImagem: cliente.caminhoImagem,
    bairro: cliente.bairro,
    clientesTelefones: cliente.clientesTelefones,
    dataNascimento: cliente.dataNascimento,
    cpf: cliente.cpf,
    sexo: cliente.sexo,
    pontuacao: cliente.pontuacao,
    pontuacaoTotal: cliente.pontuacaoTotal,
    observacao: cliente.observacao
  }
}

export function isLoading() {
  return {
    type: 'CLIENTE_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CLIENTE_NOTISLOADING'
  }
}

export function createClienteInfo() {
  return {
    type: 'CLIENTE_CREATED_SUCCESSFULLY'
  }
}
export function deleteClientesDetails() {
  return {
    type: 'DELETED_CLIENTE_DETAILS'
  }
}

export function isModalOpen() {
  return {
    type: 'CLIENTE_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'CLIENTE_NOTISMODALOPEN'
  }
}
