import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withStyles } from '@material-ui/core/styles'
import {
  Backdrop,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { sorteioAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import { Page, ImageUploader } from 'components'
import { uploadImage } from '_services'
import { errorsUploadImage, getInitials } from 'utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const SorteioEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, sorteio, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const [imagesBanner, setImagesBanner] = useState([])
  const [imagemBanner, setImagemBanner] = useState('')
  const [openBanner, setOpenBanner] = useState(false)

  const confirm = useConfirm()

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(sorteioAction.getSorteioById(params.id))
    } else {
      dispatch(sorteioAction.clear())
    }

    setImagem(null)
    setImagemBanner(null)

  }, []);

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...sorteio,
        caminhoImagem: null
      }
      dispatch(
        sorteioAction.editSorteioInfo(sorteio.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(sorteioAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...sorteio,
          caminhoImagem: response.toString()
        }

        if (sorteio.id !== 0) {
          dispatch(
            sorteioAction.editSorteioInfo(sorteio.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(sorteioAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const removeImageBanner = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...sorteio,
        caminhoImagemBanner: null
      }
      dispatch(
        sorteioAction.editSorteioInfo(sorteio.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(sorteioAction.onChangeProps('caminhoImagemBanner', event2))
      setImagemBanner(null)
    })
  }

  const onChangeImageBanner = (imageList) => {
    // data for submit
    setOpenBanner(true)
    setImagesBanner(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...sorteio,
          caminhoImagemBanner: response.toString()
        }

        if (sorteio.id !== 0) {
          dispatch(
            sorteioAction.editSorteioInfo(sorteio.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(sorteioAction.onChangeProps('caminhoImagemBanner', event2))

          setImagemBanner(response)
          setOpenBanner(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {

  }, [ready, props.sorteio.isLoading])

  useEffect(() => {
    setImagem(sorteio.caminhoImagem)
  }, [sorteio.caminhoImagem, open]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {
    setImagemBanner(sorteio.caminhoImagemBanner)
  }, [sorteio.caminhoImagemBanner, openBanner]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [openBanner, imagemBanner]);

  const handleChange = (prop) => (event) => {
    dispatch(sorteioAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.sorteio.nome,
      descricao: props.sorteio.descricao,
      dataHoraInicio: moment(props.sorteio.dataHoraInicio).format("YYYY-MM-DD"),
      dataHoraInicioInscricao: moment(props.sorteio.dataHoraInicioInscricao).format("YYYY-MM-DD"),
      dataHoraTermino: moment(props.sorteio.dataHoraTermino).format("YYYY-MM-DD"),
      dataHoraSorteio: moment(props.sorteio.dataHoraSorteio).format("YYYY-MM-DD"),
      valorNumero: props.sorteio.valorNumero,
      linkRegulamento: props.sorteio.linkRegulamento,
      caminhoImagem: props.sorteio.caminhoImagem,
      caminhoImagemBanner: props.sorteio.caminhoImagemBanner,
      gerarNumerosSorte: props.sorteio.gerarNumerosSorte
    }

    if (params.id) {
      dispatch(sorteioAction.editSorteioInfo(params.id, payload, true))
    } else {
      dispatch(sorteioAction.createSorteio(payload))
    }
  };

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Sorteio'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Sorteio'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Sorteios - Editar Sorteio'
          : 'Gerência de Sorteios - Novo Sorteio'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>

          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={500000}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              removeImage={removeImage}
              props={sorteio}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

          <Grid item md={12} xs={12} style={{ marginTop: 30 }}>
            <Backdrop className={classes.backdrop} open={openBanner}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={imagesBanner}
              onChangeImage={onChangeImageBanner}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={2000000}
              resolutionWidth={1200}
              resolutionHeight={370}
              isBanner
              resolutionType={"less"}
              removeImage={removeImage}
              props={sorteio}
              imagem={imagemBanner}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formSorteio"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Sorteios"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.sorteio.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição *"
                        margin="normal"
                        onChange={handleChange('descricao')}
                        value={props.sorteio.descricao || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        id="dataHoraInicio"
                        InputLabelProps={{ shrink: true }}
                        label="Data/Hora Início *"
                        margin="normal"
                        onChange={handleChange('dataHoraInicio')}
                        value={props.sorteio.dataHoraInicio ? moment(props.sorteio.dataHoraInicio).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        id="dataHoraInicioInscricao"
                        InputLabelProps={{ shrink: true }}
                        label="Data/Hora Início Inscrição *"
                        margin="normal"
                        onChange={handleChange('dataHoraInicioInscricao')}
                        value={props.sorteio.dataHoraInicioInscricao ? moment(props.sorteio.dataHoraInicioInscricao).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }} //evita o bug de ficar a label "bugada"
                        id="dataHoraTermino"
                        label="Data/Hora Término *"
                        onChange={handleChange('dataHoraTermino')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        margin="normal"
                        value={props.sorteio.dataHoraTermino ? moment(props.sorteio.dataHoraTermino).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }} //evita o bug de ficar a label "bugada"
                        id="dataHoraSorteio"
                        label="Data/Hora Sorteio *"
                        onChange={handleChange('dataHoraSorteio')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        margin="normal"
                        value={props.sorteio.dataHoraSorteio ? moment(props.sorteio.dataHoraSorteio).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="valorNumero"
                        label="Valor/Número *"
                        margin="normal"
                        type="number"
                        onChange={handleChange('valorNumero')}
                        value={props.sorteio.valorNumero || 0}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="linkRegulamento"
                        label="Link do regulamento *"
                        margin="normal"
                        onChange={handleChange('linkRegulamento')}
                        value={props.sorteio.linkRegulamento || ''}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.sorteio.gerarNumerosSorte}
                              onChange={handleChange('gerarNumerosSorte')}
                              color="primary"
                            />
                          }
                          label="Gerar números da sorte"
                        />
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Sorteios')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.sorteio.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.sorteio.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.sorteio.isLoading ? 'Atualizando sorteio...' : 'Atualizar') : (props.sorteio.isLoading ? 'Salvando sorteio...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

SorteioEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedSorteioEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(SorteioEdit))
)
export { connectedSorteioEditPage as SorteioEdit }
