import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Grid,
  Input,
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    display: 'none',
  },
}))

const FilePicker = ({ fileType, value, onChangeFile }) => {
  const classes = useStyles()

  return (
    <>
      <Grid>
        <Input
          id="arquivo"
          className={classes.input}
          onChange={onChangeFile}
          type="file"
          inputProps={{
            accept: fileType
          }}
          formControl
          margin="normal"
        />
        <label htmlFor="arquivo">
          <Button variant="contained" color="primary" component="span">
            Arquivo
          </Button>
        </label>
      </Grid>
      <Grid style={{ width: '100%' }}>
        <TextValidator
          disabled
          fullWidth
          margin="normal"
          label="Nome Arquivo *"
          value={value}
          variant="outlined"
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
        />
      </Grid>
    </>
  )
}

export default FilePicker
