import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const usuarioAction = {
  getUsuario,
  getUsuariosClientes,
  getUsuarioSelect,
  getUsuarioById,
  onChangeProps,
  editUsuarioInfo,
  createUsuario,
  deleteUsuarioById,
  getUsuarioByEstabelecimento,
  emailVerification,
  requestEmailVerification,
  requestPasswordReset,
  passwordReset,
  openModalRequestPasswordReset,
  clear
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getUsuario(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuariosClientes(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/usuariosClientes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function requestEmailVerification(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/request-email-verification'
    crudService
      .post(apiEndpoint, payload)
      .then(() => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_EMAIL_VERIFICATION_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_FAILURE', error }
  }
}

function createUsuario(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'usuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/usuarios')
        } else {
          toast.err('Oops! Erro ao cadastrar usuário! 😥')
      }
    })
      .catch((err) => {
       // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.err('Oops! Erro ao cadastrar usuário! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_USER_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_USER_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'USER_CLEAR'
  }
}

function requestPasswordReset(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(email))
    let apiEndpoint = 'usuarios/request-reset-password'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(notIsLoading())
        if (response && response.request.status === 200) {
          toast.success('E-mail de recuperação enviado! 😎')
        } else if (response && response.request.status === 404) {
          toast.warn('Não existe nenhum usuário com este e-mail')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log('err', err)
        dispatch(failure(err))
        toast.error('Erro ao recuperar senha do usuário')
      })
  }
  function request(payload) {
    return { type: 'REQUEST_RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_RESET_PASSWORD_FAILURE', error }
  }
}

function passwordReset(payload, token) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/reset-password'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(success(response.data))
          toast.success('Senha redefinida com sucesso! 😎')
          history.push('/password-reset-success')
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
  function request(payload) {
    return { type: 'RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'RESET_PASSWORD_FAILURE', error }
  }
}

function emailVerification(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/email-verification'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          toast.success('E-mail verificado com sucesso! 😎')
          history.push('/')
          dispatch(success())
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao verificar e-mail do usuário')
        }
      })
      .catch(() => {
        toast.error('Erro ao verificar e-mail do usuário')
      })
  }

  function success() {
    return {
      type: 'VERIFICATION_USER_SUCCESS'
    }
  }
}

function editUsuarioInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
            dispatch(notIsLoading())
            history.push('/usuarios')
        } else {
        //  toast.error('Oops! Erro ao alterar usuário! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar usuário! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_USER_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_USER_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_USER_FAILURE', error }
  }
}

function getUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editUsuariosDetails(response.data))
    })
  }
}
function getUsuarioByEstabelecimento(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/estabelecimento/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeUsuariosList(response.data))
    })
  }
}
export function openModalRequestPasswordReset(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_PASSWORD_RESET',
    open: open
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuarios/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteUsuariosDetails())
      dispatch(notIsLoading())
    })
  }
}
export function changeUsuariosList(usuario, totalRows) {
  return {
    type: 'FETCHED_ALL_USUARIO',
    usuario: usuario,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'USUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editUsuariosDetails(usuario) {
  return {
    type: 'USUARIO_DETAIL',
    id: usuario.id,
    isLoading: usuario.isLoading,
    email: usuario.email,
    nome: usuario.nome,
    senha: usuario.senha,
    sobrenome: usuario.sobrenome,
    telefone: usuario.telefone,
    idTipoUsuario: usuario.idTipoUsuario,
    caminhoImagem: usuario.caminhoImagem,
    telefoneVerificado: usuario.telefoneVerificado,
    emailVerificado: usuario.emailVerificado,
    dataHoraUltimoAcesso: usuario.dataHoraUltimoAcesso
  }
}
export function createUserInfo() {
  return {
    type: 'USUARIO_CREATED_SUCCESSFULLY'
  }
}
export function deleteUsuariosDetails() {
  return {
    type: 'DELETED_USUARIO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'USUARIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'USUARIO_NOTISLOADING'
  }
}
