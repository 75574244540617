import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SmartphoneTwoToneIcon from '@material-ui/icons/SmartphoneTwoTone';
import ComputerTwoToneIcon from '@material-ui/icons/ComputerTwoTone';
import {
  Box,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  CircularProgress,
  Link,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Input,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import {
  SelectValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { pedidoCompraAction, totvsInsumoUnidadeAction, fornecedorAction } from '../../_actions'
import { withRouter, Prompt } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { history } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import NotesIcon from '@material-ui/icons/Notes';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import moment from 'moment'
import { toast } from 'react-toastify'
import RestaurantRoundedIcon from '@material-ui/icons/RestaurantRounded'
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded'
import StoreRoundedIcon from '@material-ui/icons/StoreRounded'
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded'
import KitchenRoundedIcon from '@material-ui/icons/KitchenRounded'
import LocalBarRoundedIcon from '@material-ui/icons/LocalBarRounded'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import NatureRoundedIcon from '@material-ui/icons/NatureRounded'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import LocalCafeRoundedIcon from '@material-ui/icons/LocalCafeRounded'
import { PedidoCompraModal } from './pedidoCompraModal.component';
import { InsumoUnidadeModal } from '../InsumosUnidades/insumoUnidadeModal.component';
import { useDebounce } from 'use-debounce';
import './pedidoCompra.css'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import palette from '../../theme/palette'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 90,
    width: 90,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white
  },
  variant60: {
    width: '60%',
    fontSize: 11,
  },
  variant50: {
    width: '50%',
    fontSize: 12,
  },
  variantMobile: {
    width: '100%',
    textAlign: 'right',
    fontSize: 11,
  },
  variant43: {
    width: '43%',
    fontSize: 12,
  },
  fontTypography: {
    fontSize: 6.5
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 800
  },
  stickyButtons: {
    bottom: 0,
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 800,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E2E3',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  fontTypographyHeader: {
    fontWeight: 500
  },
  fontTypographySmall: {
    fontSize: 11,
    fontWeight: 500
  },
  buttonGrid: {
    paddingBottom: 8,
    paddingRight: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingRight: 0
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
      alignItems: 'right',
      textAlign: 'right',
      justifyContent: 'right',
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})


const PedidoCompraEdit = (props) => {
  const NOT_APPLICABLE = "Não aplicável"

  const isAndroid = /Android/i.test(navigator.userAgent);

  const dispatch = useDispatch()
  const { classes, pedidoCompra, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const theme = useTheme()
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'));
  const [isMobile, setIsMobile] = useState(false);

  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 200);
  const [filteredTotvsInsumosUnidades, setFilteredTotvsInsumosUnidades] = useState([]);
  const [tab, setTab] = useState(7);
  const [editable, setEditable] = useState(false);

  const [isModified, setIsModified] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const handleTabsChange = (event, value) => {
    setTab(value);
  }

  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(0);
  const toggle = () => setModal(!modal);

  const [modalInsumoUnidade, setModalInsumoUnidade] = useState(false);
  const [modalIdInsumoUnidade, setModalIdInsumoUnidade] = useState(0);
  const toggleInsumoUnidade = () => setModalInsumoUnidade(!modalInsumoUnidade);

  const [load, setLoad] = useState(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { totvsInsumoUnidade } = useSelector(
    (state) => state.totvsInsumoUnidade
  )

  const isLoadingTotvsInsumoUnidade = useSelector(
    (state) => state.totvsInsumoUnidade.isLoading
  )

  const fornecedores = useSelector((state) => state.fornecedor.fornecedor)

  useEffect(() => {

    dispatch(fornecedorAction.clear())
    dispatch(totvsInsumoUnidadeAction.clear())
    dispatch(pedidoCompraAction.clear())
    dispatch(pedidoCompraAction.clearPedidosComprasProdutos())

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
      history.push('/pedidosCompras');
    }

    setLoad(true);

  }, []);

  useEffect(() => {
    setIsMobile(isMobileResolution);
  }, [isMobileResolution]);

  useEffect(() => {

    if (params.id) {
      dispatch(pedidoCompraAction.getPedidoCompraById(params.id))
    }

  }, [params.id]);

  useEffect(() => {

    //if (load && idLoja !== '' && idLoja !== undefined && idLoja !== 0) {
    if (idLoja && Number.isInteger(idLoja)) {
      dispatch(fornecedorAction.getFornecedorSelectLoja(idLoja));
    }

  }, [load, idLoja]);

  useEffect(() => {

    if (load && idLoja !== '' && idLoja !== undefined && idLoja !== 0 && props.pedidoCompra.idFornecedor !== 0 && props.pedidoCompra.idFornecedor !== '' && props.pedidoCompra.idFornecedor !== undefined) {

      setFilteredTotvsInsumosUnidades([])

      //if (props.pedidoCompra && (props.pedidoCompra.situacao == 'E' || props.pedidoCompra.situacao == 'A')) {

      let filterModel = {
        IdLoja: idLoja,
        IdFornecedor: props.pedidoCompra.idFornecedor,
        Data: props.pedidoCompra.dataHora,
        IdPedidoCompra: props.pedidoCompra.id
      };

      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeFornecedor(filterModel, true))

      //}
      //else {

      //  let filterModel = {
      //    IdLoja: idLoja,
      //    IdFornecedor: props.pedidoCompra.idFornecedor,
      //    IdPedidoCompra: props.pedidoCompra.id
      //  };

      //  dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeFornecedor(filterModel, true))

      //}

    }

  }, [load, idLoja, props.pedidoCompra.idFornecedor, props.pedidoCompra.id]);

  useEffect(() => {
    if (totvsInsumoUnidade && totvsInsumoUnidade.totvsInsumosUnidades) {
      setFilteredTotvsInsumosUnidades(totvsInsumoUnidade.totvsInsumosUnidades.filter(filterTerm))
    }
  }, [totvsInsumoUnidade, debounceTerm, tab]);

  useEffect(() => {
    if (totvsInsumoUnidade && totvsInsumoUnidade.totvsInsumosUnidades) {

      // verifica primeira categoria com registros
      if (totvsInsumoUnidade != null && totvsInsumoUnidade.multiCategoria == true) {

        for (let i = 0; i < tabs.length; i++) {
          const element = tabs[i].value;
          if (hasTab(element) == true) {
            setTab(element);
            break;
          }

        }

      } else {
        setTab(null);
      }

    }
  }, [totvsInsumoUnidade, totvsInsumoUnidade.multiCategoria]);

  useEffect(() => {
    // Exibe alerta ao recarregar a página com modificações não salvas
    window.onbeforeunload = isModified ? () => true : undefined;

    if (nextLocation && !window.onbeforeunload) {
      history.push(nextLocation);
    }

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [isModified]);

  const handleBlockedNavigation = (location) => {
    if (!isModified) return true;

    setNextLocation(location.pathname);
    setOpenDialog(true);

    return false;
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);

    if (confirm) {
      setIsModified(false);
    }
  };

  function filterTerm(obj) {
    if (obj) {
      if (tab == null || tab == '' || (obj.categoriaInsumo && obj.categoriaInsumo.id == tab)) {
        if (debounceTerm == null || debounceTerm == '' || (obj.nome != null && obj.nome != '' && (obj.nome.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()).includes(debounceTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function hasTab(obj) {

    const totvsInsumoUnidadeTab = totvsInsumoUnidade;

    if (totvsInsumoUnidadeTab != null && totvsInsumoUnidadeTab.multiCategoria == true &&
      obj && totvsInsumoUnidadeTab && totvsInsumoUnidadeTab.totvsInsumosUnidades && totvsInsumoUnidadeTab.totvsInsumosUnidades.length > 0) {
      return totvsInsumoUnidadeTab.totvsInsumosUnidades.some(e => e.categoriaInsumo && e.categoriaInsumo.id == obj);
    }

    return false;
  }

  function hasQuantidade() {
    const { pedidoCompra } = props;
    if (pedidoCompra.idFornecedor != null && pedidoCompra.idFornecedor != 0 && pedidoCompra.pedidosComprasProdutos?.length) {
      return pedidoCompra.pedidosComprasProdutos.some(e => e.quantidade != null && e.quantidade > 0);
    }
    return false;
  }

  useEffect(() => {
    if (props.pedidoCompra.isModalOpen) {
      setModalId(props.pedidoCompra.id);
      toggle();
    }
  }, [props.pedidoCompra.isModalOpen])

  useEffect(() => {
    if (props.totvsInsumoUnidade.isModalOpen) {
      setModalIdInsumoUnidade(props.totvsInsumoUnidade.id);
      toggle();
    }
  }, [props.totvsInsumoUnidade.isModalOpen])

  const handleChange = (prop) => (event) => {
    dispatch(pedidoCompraAction.onChangeProps(prop, event))
  }

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  const handleOpenModal = (id) => {
    toggleInsumoUnidade();

    setModalIdInsumoUnidade(id)
  }

  const handleEdit = () => {
    setEditable(true);
  }

  const handleChangeQuantidade = (event, prop, n) => {
    const pedidosComprasProdutos = props.pedidoCompra?.pedidosComprasProdutos;

    if (pedidosComprasProdutos?.length) {
      const index = pedidosComprasProdutos.findIndex(x => x.idTotvsInsumoUnidade == n.id);
      if (index >= 0) {
        const novosPedidosComprasProdutos = pedidosComprasProdutos.map(x =>
          x.idTotvsInsumoUnidade == n.id ? { ...x, quantidade: event.target.value } : x
        );
        dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', novosPedidosComprasProdutos));
      } else {
        const payload = {
          id: 0,
          idPedidoCompra: (params.id ? params.id : 0),
          codigoProduto: n.codigoExterno,
          idTotvsInsumoUnidade: n.id,
          quantidade: event.target.value,
          valorUnitario: n.preco
        };
        pedidosComprasProdutos.push(payload);
        dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', pedidosComprasProdutos));
      }

      setIsModified(true)
    } else {
      const payload = {
        id: 0,
        idPedidoCompra: (params.id ? params.id : 0),
        codigoProduto: n.codigoExterno,
        idTotvsInsumoUnidade: n.id,
        quantidade: event.target.value,
        valorUnitario: n.preco
      }

      pedidosComprasProdutos.push(payload);
      dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', pedidosComprasProdutos))

      setIsModified(true)
    }
  };

  const handleChangeContagem = (event, prop, n) => {
    const pedidosComprasProdutos = props.pedidoCompra?.pedidosComprasProdutos;

    if (pedidosComprasProdutos?.length) {
      const index = pedidosComprasProdutos.findIndex(x => x.idTotvsInsumoUnidade == n.id);
      if (index >= 0) {
        const novosPedidosComprasProdutos = pedidosComprasProdutos.map(x =>
          x.idTotvsInsumoUnidade == n.id ? { ...x, contagem: event.target.value } : x
        );
        dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', novosPedidosComprasProdutos));
      } else {
        const payload = {
          id: 0,
          idPedidoCompra: (params.id ? params.id : 0),
          codigoProduto: n.codigoExterno,
          idTotvsInsumoUnidade: n.id,
          contagem: event.target.value,
          valorUnitario: n.preco
        };
        pedidosComprasProdutos.push(payload);
        dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', pedidosComprasProdutos));
      }

      setIsModified(true)
    } else {
      const payload = {
        id: 0,
        idPedidoCompra: (params.id ? params.id : 0),
        codigoProduto: n.codigoExterno,
        idTotvsInsumoUnidade: n.id,
        contagem: event.target.value,
        valorUnitario: n.preco
      }

      pedidosComprasProdutos.push(payload);
      dispatch(pedidoCompraAction.onChangeProps('pedidosComprasProdutos', pedidosComprasProdutos))

      setIsModified(true)
    }
  };

  const handleClickBack = () => {
    if (totvsInsumoUnidade.pedidoIdAnt != undefined) {
      history.push(`/pedidoCompra/${totvsInsumoUnidade.pedidoIdAnt}`);
    }
  }

  const handleClickNext = () => {
    if (totvsInsumoUnidade.pedidoIdProx != undefined) {
      history.push(`/pedidoCompra/${totvsInsumoUnidade.pedidoIdProx}`);
    }
  }

  function getQuantidade(codigo) {
    const { pedidoCompra } = props;
    const { pedidosComprasProdutos } = pedidoCompra;
    const produto = pedidosComprasProdutos?.find(p => p.idTotvsInsumoUnidade == codigo);
    if (produto) {
      return produto.quantidade;
    }
    return null;
  }

  function getContagem(codigo) {
    const { pedidoCompra } = props;
    const { pedidosComprasProdutos } = pedidoCompra;
    const produto = pedidosComprasProdutos?.find(p => p.idTotvsInsumoUnidade == codigo);
    if (produto) {
      return produto.contagem;
    }
    return null;
  }

  function getTotal() {
    const { pedidoCompra } = props;
    const pedidosComprasProdutos = pedidoCompra?.pedidosComprasProdutos;

    if (pedidosComprasProdutos?.length) {
      let total = pedidosComprasProdutos.reduce(function (acumulador, valorAtual) {
        return acumulador + ((valorAtual.quantidade ?? 0) * parseFloat(valorAtual.valorUnitario ?? 0));
      }, 0);
      return total.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    } else {
      return (0.00).toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
  }

  const handleSubmit = (event, enviar, redirect) => {

    event.preventDefault();

    const editedPedidosComprasProdutos = props.pedidoCompra.pedidosComprasProdutos.map((item) => {
      return {
        ...item,
        contagem: typeof item.contagem === 'string' ? parseFloat(item.contagem?.replace(',', '.')) : item.contagem,
        quantidade: typeof item.quantidade === 'string' ? parseFloat(item.quantidade?.replace(',', '.')) : item.quantidade
      }
    })

    if (navigator.onLine) {

      let payload = {
        id: (params.id ? params.id : 0),
        idLoja: idLoja,
        idFornecedor: props.pedidoCompra.idFornecedor,
        dataHora: (params.id ? moment(props.pedidoCompra.dataHora).format("yyyy-MM-DDTHH:mm") : moment().format('yyyy-MM-DDTHH:mm')),
        situacao: props.pedidoCompra.situacao,
        pedidosComprasProdutos: editedPedidosComprasProdutos,
        enviar: enviar
      }

      if (params.id) {
        dispatch(pedidoCompraAction.editPedidoCompraInfo(params.id, payload, redirect, toggle, setModalId))
      } else {
        dispatch(pedidoCompraAction.createPedidoCompra(payload, redirect, toggle, setModalId))
      }

      setIsModified(false);

    } else {
      toast.error('Você está offline! 😥 Conecte-se a internet para prosseguir com o pedido de compra!');
    }
  };

  const tabs = []
  {
    tabs.push({
      value: 7,
      label: 'BEBIDAS',
      icon: <LocalBarRoundedIcon />,
      disabled: !hasTab(7)
    })
  }
  {
    tabs.push({
      value: 2,
      label: 'FRENTE',
      icon: <LocalCafeRoundedIcon />,
      disabled: !hasTab(2)
    })
  }
  {
    tabs.push({
      value: 3,
      label: 'EMBALAGEM',
      icon: <ShoppingBasketRoundedIcon />,
      disabled: !hasTab(3)
    })
  }
  {
    tabs.push({
      value: 1,
      label: 'COZINHA',
      icon: <KitchenRoundedIcon />,
      disabled: !hasTab(1)
    })
  }
  {
    tabs.push({
      value: 5,
      label: 'LOJA',
      icon: <StoreRoundedIcon />,
      disabled: !hasTab(5)
    })
  }
  {
    tabs.push({
      value: 4,
      label: 'UTENSÍLIO',
      icon: <RestaurantRoundedIcon />,
      disabled: !hasTab(4)
    })
  }
  {
    tabs.push({
      value: 8,
      label: 'UNIFORMES',
      icon: <GroupRoundedIcon />,
      disabled: !hasTab(8)
    })
  }
  {
    tabs.push({
      value: 6,
      label: 'HORTIFRUTI',
      icon: <NatureRoundedIcon />,
      disabled: !hasTab(6)
    })
  }
  {
    tabs.push({
      value: 0,
      label: 'TODOS',
      icon: <ListAltRoundedIcon />
    })
  }

  const getColor = (data) => {

    var diff = moment().diff(moment(data, 'DD/MM/YYYY'), 'days')
    if (diff > 1) {
      return palette.error.main
    }
    else {
      return palette.text.primary
    }

  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Pedido de Compra'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Pedido de Compra'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SubHeaderCard() {
    const data = totvsInsumoUnidade?.dataUltimaVendaImportada;

    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {totvsInsumoUnidade != null && data != null && data != '' &&
          <>
            <Typography>Última atualização:</Typography>
            <Typography style={{ color: getColor(data), marginLeft: '5px' }}>{data}</Typography>
            <Typography style={{ marginRight: '10px' }}>.</Typography>
          </>
        }
        <Typography>{(props.pedidoCompra != null && props.pedidoCompra.dataHora != null && props.pedidoCompra.dataHora != '' ? "Data do Pedido: " + moment(props.pedidoCompra.dataHora).format('DD/MM/YYYY HH:mm') + '.' : '')}</Typography>
      </Box>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Pedidos de Compras - Editar Pedido de Compra'
          : 'Gerência de Pedidos de Compras - Novo Pedido de Compra'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formPedidoCompra"
              >
                <CardHeader
                  subheader={<SubHeaderCard />}
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >

                  {!isEmpty(totvsInsumoUnidade.mensagens) &&
                    undefined !== totvsInsumoUnidade.mensagens &&
                    totvsInsumoUnidade.mensagens.length &&
                    totvsInsumoUnidade.mensagens.map((row, index) => (
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          <Alert severity={(row.tipo == 'U' ? "error" : (row.tipo == 'A' ? "warning" : (row.tipo == 'I' ? "info" : "error")))}>
                            <AlertTitle><strong>{row.titulo}</strong></AlertTitle>
                            {row.textoMensagem}
                          </Alert>
                          <br />
                        </Grid>
                      </Grid>
                    ))}

                  <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idFornecedor"
                        label="Fornecedor *"
                        value={props.pedidoCompra.idFornecedor}
                        onChange={handleChange('idFornecedor')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idFornecedor"
                        disabled={hasQuantidade()}
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Fornecedor *</em>
                        </MenuItem>
                        {!isEmpty(fornecedores) &&
                          undefined !== fornecedores &&
                          fornecedores.length &&
                          Array.isArray(fornecedores) &&
                          fornecedores.map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} align="right">
                      <Grid
                        style={{ justifyContent: 'right', alignContent: 'end' }}
                        container
                        spacing={3}
                      >
                        <Grid item>
                          <IconButton color="inherit" onClick={handleClickBack} disabled={totvsInsumoUnidade.pedidoIdAnt == undefined}>
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                          <IconButton color="inherit" onClick={handleClickNext} disabled={totvsInsumoUnidade.pedidoIdProx == undefined}>
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} xs={12} align="right">
                      <ButtonGroup variant="outlined">
                        <Tooltip title="Versão para dispositivo móvel">
                          <Button
                            onClick={() => setIsMobile(true)}
                            style={{ backgroundColor: isMobile ? '#E0E0E0' : 'inherit' }}>
                            <SmartphoneTwoToneIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Versão para computador">
                          <Button
                            onClick={() => setIsMobile(false)}
                            style={{ backgroundColor: !isMobile ? '#E0E0E0' : 'inherit' }}>
                            <ComputerTwoToneIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <br />
                  {(props.pedidoCompra.idFornecedor && props.pedidoCompra.idFornecedor != '' ?
                    <>
                      <Grid container spacing={2}>
                        {(totvsInsumoUnidade != null && totvsInsumoUnidade.multiCategoria == true ?
                          <Grid item xl={10} lg={10} md={12} xs={12}>
                            <Tabs
                              className={classes.tabs}
                              onChange={handleTabsChange}
                              scrollButtons="auto"
                              value={tab}
                              indicatorColor="primary"
                              textColor="primary"
                              variant={'scrollable'}
                            >
                              {tabs.map((tab) => (
                                <Tab
                                  disabled={tab.disabled}
                                  key={tab.value}
                                  label={tab.label}
                                  value={tab.value}
                                  icon={tab.icon}
                                />
                              ))}
                            </Tabs>
                          </Grid>
                          :
                          null
                        )}
                        <Grid item xl={2} lg={2} md={12} xs={12}>
                          {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                            <Typography style={{ fontSize: "22px", fontWeight: 'bold', textAlign: "right", marginRight: 5 }}>
                              TOTAL: R$ {getTotal()}
                            </Typography>
                            :
                            null
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Grid item xl={6} lg={6} md={6} xs={12} style={{ marginTop: 20 }}>
                            <Input
                              id="term"
                              label="term"
                              placeholder="Filtro"
                              value={term}
                              onChange={handleChangeTerm('term')}
                              fullWidth
                              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            />
                          </Grid>
                          <br />
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.stickyCell} style={{
                                    backgroundColor: '#FAFAFA'
                                  }}></TableCell>
                                  {!isMobile &&
                                    <>
                                      <TableCell align="center" colspan={2}>
                                        <Typography align="center" className={classes.fontTypographyHeader}>
                                          {(totvsInsumoUnidade.periodo2Semana)}
                                        </Typography>
                                        <Typography align="center" className={classes.fontTypographySmall}>
                                          {(totvsInsumoUnidade.dataUltimoPedidoSemana2 != '' && totvsInsumoUnidade.dataUltimoPedidoSemana2 != undefined ? "Pedido em " + (totvsInsumoUnidade.dataUltimoPedidoSemana2) : '')}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="center" colspan={2}>
                                        <Typography align="center" className={classes.fontTypographyHeader}>
                                          {(totvsInsumoUnidade.periodo1Semana)}
                                        </Typography>
                                        <Typography align="center" className={classes.fontTypographySmall}>
                                          {(totvsInsumoUnidade.dataUltimoPedidoSemana1 != '' && totvsInsumoUnidade.dataUltimoPedidoSemana1 != undefined ? "Pedido em " + (totvsInsumoUnidade.dataUltimoPedidoSemana1) : '')}
                                        </Typography>
                                      </TableCell>
                                    </>
                                  }
                                  <TableCell align="center" colspan={2}>Semana atual (Sábado a sexta)</TableCell>
                                  {!isMobile && <><TableCell align="center">Últ. 7 dias</TableCell>
                                    <TableCell align="center">Últ. 14 dias</TableCell>
                                    <TableCell align="center">Méd. 14 dias</TableCell>
                                    {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                      <TableCell></TableCell>
                                      :
                                      null
                                    )}
                                    {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                      <TableCell></TableCell>
                                      :
                                      null
                                    )}</>}
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.stickyCell} style={{
                                    backgroundColor: '#FAFAFA'
                                  }}>Descrição</TableCell>
                                  {!isMobile && <><TableCell align="center">Contagem</TableCell>
                                    <TableCell align="center">Pedido</TableCell>
                                    <TableCell align="center">Contagem</TableCell>
                                    <TableCell align="center">Pedido</TableCell></>}
                                  <TableCell style={{ textAlign: isMobile && "right" }}>Contagem</TableCell>
                                  <TableCell style={{ paddingRight: !isMobile && 19, paddingLeft: !isMobile && 19, textAlign: isMobile && "right" }}>Pedido</TableCell>
                                  {!isMobile && <><TableCell align="center">Vendas</TableCell>
                                    <TableCell align="center">Vendas</TableCell>
                                    <TableCell align="center">Vendas</TableCell>
                                    {/*<TableCell align="right">Compra média por semana</TableCell>*/}
                                    {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                      <TableCell align="right">Preço Final (com Difal/ST)</TableCell>
                                      :
                                      null
                                    )}
                                    {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                      <TableCell align="right">Valor</TableCell>
                                      :
                                      null
                                    )}</>}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {idLoja !== '' && idLoja !== undefined && undefined !== filteredTotvsInsumosUnidades && filteredTotvsInsumosUnidades.length
                                  ? filteredTotvsInsumosUnidades.map((n) => {

                                    const quantidade1 = getQuantidade(n.id);
                                    const contagemValue = getContagem(n.id);
                                    const multiplos = n.pedidoMultiplo?.split(';')

                                    return (
                                      <TableRow hover key={(n ? n.id : 0)}>
                                        <TableCell className={classes.stickyCell} style={{
                                          backgroundColor: '#FFF',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          gap: 5
                                        }}>
                                          <Link
                                            color="inherit"
                                            onClick={() => handleOpenModal(n.id)}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            {(n ? n.nome.toUpperCase() : '')}
                                          </Link>
                                          {n.observacoes &&
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <Tooltip title={n.observacoes}>
                                                <NotesIcon fontSize="small" />
                                              </Tooltip>
                                            </Box>
                                          }
                                          {multiplos?.length > 0 &&
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <Tooltip title={`Compras devem ser em múltiplos de ${n.pedidoMultiplo}`}>
                                                <Typography variant="body2" style={{ color: palette.error.primary }} ><b>x{multiplos[0]}{multiplos?.length > 1 ? '...' : ''}</b></Typography>
                                              </Tooltip>
                                            </Box>
                                          }
                                          {!n.disponivel &&
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <Tooltip title={'Indisponível'}>
                                                <RemoveShoppingCartIcon fontSize="small" />
                                              </Tooltip>
                                            </Box>
                                          }
                                          {!n.contabilizado &&
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <Tooltip title={'Não Contabilizado'}>
                                                <MoneyOffIcon fontSize="small" />
                                              </Tooltip>
                                            </Box>
                                          }
                                        </TableCell>
                                        {
                                          !isMobile &&
                                          <>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="contagemSemana2"
                                                  label="contagemSemana2"
                                                  className="form-control"
                                                  value={(n.contagemSemana2 != null ? parseFloat(n.contagemSemana2).toFixed(2) : '')}
                                                  endAdornment={(parseFloat(n.contagemSemana2) > 0 ?
                                                    ((n.quantidadeUnidadeContagem) > 1 ?
                                                      <Typography position="left" className={classes.fontTypography}>{n.unidadeContagem + ' C/ ' + n.quantidadeUnidadeContagem}</Typography>
                                                      : <Typography position="left" className={classes.fontTypography}>{n.unidadeContagem}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant50,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="pedidoSemana2"
                                                  label="pedidoSemana2"
                                                  className="form-control"
                                                  value={(parseFloat(n.pedidoSemana2) > 0 ? parseFloat(n.pedidoSemana2).toFixed(0) : '')}
                                                  endAdornment={(parseFloat(n.pedidoSemana2) > 0 ?
                                                    ((n.quantidadeUnidade) > 1 ?
                                                      <Typography position="left" className={classes.fontTypography}>{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                      : <Typography position="left" className={classes.fontTypography}>{n.unidade}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant50,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="contagemSemana1"
                                                  label="contagemSemana1"
                                                  className="form-control"
                                                  value={(n.contagemSemana1 != null ? parseFloat(n.contagemSemana1).toFixed(2) : '')}
                                                  endAdornment={(parseFloat(n.contagemSemana1) > 0 ?
                                                    ((n.quantidadeUnidadeContagem) > 1 ?
                                                      <Typography position="left" className={classes.fontTypography}>{n.unidadeContagem + ' C/ ' + n.quantidadeUnidadeContagem}</Typography>
                                                      : <Typography position="left" className={classes.fontTypography}>{n.unidadeContagem}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant50,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="pedidoSemana1"
                                                  label="pedidoSemana1"
                                                  className="form-control"
                                                  value={(parseFloat(n.pedidoSemana1) > 0 ? parseFloat(n.pedidoSemana1).toFixed(0) : '')}
                                                  endAdornment={(parseFloat(n.pedidoSemana1) > 0 ?
                                                    ((n.quantidadeUnidade) > 1 ?
                                                      <Typography position="right" className={classes.fontTypography}>{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                      : <Typography position="right" className={classes.fontTypography}>{n.unidade}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant50,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                          </>
                                        }
                                        <TableCell component="th" scope="row" style={{ backgroundColor: "#FAFAFA", textAlign: isMobile && "right" }}>
                                          <Input
                                            id="contagem"
                                            label="contagem"
                                            autoComplete="off"
                                            className="form-control"
                                            //type={isAndroid ? "text" : "number"}
                                            type="number"
                                            disabled={(params.id && props.pedidoCompra.situacao != 'N' && editable == false ? true : false)}
                                            value={contagemValue}
                                            onChange={(event) => handleChangeContagem(event, 'contagem', n)}
                                            endAdornment={
                                              ((n.quantidadeUnidadeContagem) > 1 ?
                                                <Typography position="right" className={classes.fontTypography} >{n.unidadeContagem + ' C/ ' + n.quantidadeUnidadeContagem}</Typography>
                                                :
                                                <Typography position="right" className={classes.fontTypography}>{n.unidadeContagem}</Typography>)}
                                            inputProps={{
                                              min: 0,
                                              className: !isMobile ? classes.variant60 : classes.variantMobile,
                                              step: 1
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{ backgroundColor: "#FAFAFA", textAlign: isMobile && "right" }}>
                                          <Tooltip title={((!n.disponivel && editable == false) ? 'Indisponível' : '')}>
                                            <Input
                                              id="quantidade"
                                              label="quantidade"
                                              autoComplete="off"
                                              className="form-control"
                                              type="number"
                                              disabled={params.id && props.pedidoCompra.situacao != 'N' && editable == false || (!n.disponivel && editable == false)}
                                              value={quantidade1}
                                              onChange={(event) => handleChangeQuantidade(event, 'quantidade', n)}
                                              endAdornment={
                                                ((n.quantidadeUnidade) > 1 ?
                                                  <Typography position="right" className={classes.fontTypography}>{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                  :
                                                  <Typography position="right" className={classes.fontTypography}>{n.unidade}</Typography>)}
                                              inputProps={{
                                                min: 0,
                                                className: !isMobile ? classes.variant60 : classes.variantMobile,
                                                step: (n.pedidoMultiplo != null && n.pedidoMultiplo != 0 && n.pedidoMultiplo != '' && !n.pedidoMultiplo.includes(";") ? n.pedidoMultiplo.replace(",", ".") : 1)
                                              }}
                                            />
                                          </Tooltip>
                                        </TableCell>
                                        {
                                          !isMobile &&
                                          <>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="vendas7dias"
                                                  label="vendas7dias"
                                                  className="form-control"
                                                  value={(parseFloat(n.vendas7dias) > 0 ? parseFloat(n.vendas7dias).toFixed(2) : '')}
                                                  endAdornment={(parseFloat(n.vendas7dias) > 0 ?
                                                    ((n.quantidadeUnidade) > 1 ?
                                                      <Typography className={classes.fontTypography} position="left" >{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                      : <Typography className={classes.fontTypography} position="left">{n.unidade}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant60,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="vendas14dias"
                                                  className="form-control"
                                                  label="vendas14dias"
                                                  value={(parseFloat(n.vendas14dias) > 0 ? (n.vendas14dias).toFixed(2) : '')}
                                                  endAdornment={(parseFloat(n.vendas14dias) > 0 ?
                                                    ((n.quantidadeUnidade) > 1 ?
                                                      <Typography className={classes.fontTypography} position="left">{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                      : <Typography className={classes.fontTypography} position="left">{n.unidade}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant60,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              {n.contagem ?
                                                <Input
                                                  id="mediaVendasDiaUlt14"
                                                  label="mediaVendasDiaUlt14"
                                                  className="form-control"
                                                  value={(parseFloat(n.vendas14dias) > 0 ? parseFloat(n.vendas14dias / 14).toFixed(2) : '')}
                                                  endAdornment={(parseFloat(n.vendas14dias) > 0 ?
                                                    ((n.quantidadeUnidade) > 1 ?
                                                      <Typography className={classes.fontTypography} position="left" >{n.unidade + ' C/ ' + n.quantidadeUnidade}</Typography>
                                                      : <Typography className={classes.fontTypography} position="left">{n.unidade}</Typography>)
                                                    : '')}
                                                  inputProps={{
                                                    className: classes.variant60,
                                                    tabIndex: -1
                                                  }}
                                                />
                                                : NOT_APPLICABLE}
                                            </TableCell>
                                            {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                              <TableCell component="th" scope="row" align="right">
                                                <Input
                                                  id="valor"
                                                  label="Valor"
                                                  className="form-control"
                                                  value={'R$ ' + (parseFloat(n.preco).toFixed(2).toString())}
                                                  fullWidth
                                                  inputProps={{
                                                    style: { textAlign: "right", fontSize: 11 },
                                                    tabIndex: -1
                                                  }}
                                                />
                                              </TableCell>
                                              :
                                              null
                                            )}
                                            {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                              <TableCell component="th" scope="row">
                                                <Input
                                                  id="valor"
                                                  label="Valor"
                                                  className="form-control"
                                                  value={(quantidade1 != null && parseFloat(quantidade1) > 0 ? 'R$ ' + (parseFloat(quantidade1 * n.preco || 0).toFixed(2).toString()) : '')}
                                                  fullWidth
                                                  inputProps={{
                                                    style: { textAlign: "right", fontSize: 11 },
                                                    tabIndex: -1
                                                  }}
                                                />
                                              </TableCell>
                                              :
                                              null
                                            )}
                                          </>
                                        }
                                      </TableRow>
                                    )
                                  })
                                  :
                                  (idLoja != '' ?
                                    isLoadingTotvsInsumoUnidade ? Array.apply(null, { length: 6 }).map((e, i) => (
                                      <TableRow hover key={i}>
                                        <TableCell component="th" scope="row">
                                          <Skeleton animation="wave" />
                                        </TableCell>
                                        {!isMobile &&
                                          <>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                          </>
                                        }
                                        <TableCell component="th" scope="row" align="right">
                                          <Skeleton animation="wave" />
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                          <Skeleton animation="wave" />
                                        </TableCell>
                                        {!isMobile &&
                                          <>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                            {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                              <TableCell component="th" scope="row" align="right">
                                                <Skeleton animation="wave" />
                                              </TableCell>
                                              :
                                              null
                                            )}
                                            {(totvsInsumoUnidade != null && totvsInsumoUnidade.listaPreco == true ?
                                              <TableCell component="th" scope="row">
                                                <Skeleton animation="wave" />
                                              </TableCell>
                                              :
                                              null
                                            )}
                                          </>
                                        }
                                      </TableRow>
                                    )) :
                                      <TableRow>
                                        <TableCell rowSpan={3} colSpan={8} align="center">Sem itens no pedido</TableCell>
                                      </TableRow>
                                    :
                                    <TableRow>
                                      <TableCell rowSpan={3} colSpan={8} align="center">Informe uma Loja</TableCell>
                                    </TableRow>
                                  )

                                }

                              </TableBody>
                            </Table>
                          </TableContainer>

                        </Grid>
                      </Grid>

                    </>

                    :
                    null
                  )}
                  {params.id && editable == true ?
                    <Grid item lg={12} md={12} xl={12} xs={12} spacing={2}>
                      <Typography variant="body2" style={{ paddingTop: 10, fontWeight: 600 }}>
                        * As informações alteradas nesta tela são apenas para fins de histórico, pois o pedido de compra já foi enviado.
                      </Typography>
                    </Grid>
                    : null}
                </CardContent>
              </ValidatorForm>
            </Card>
            <Grid container className={classes.stickyButtons}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography style={{ fontSize: "10px", paddingLeft: 10, paddingTop: 4 }}>
                  * Campos obrigatórios
                </Typography>
              </Grid>
              <Grid container lg={12} md={12} xl={12} xs={12} className={classes.buttonGrid}>
                <Grid item>
                  <Button
                    className={classes.button}
                    color="default"
                    onClick={() => history.push('/pedidosCompras')}
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Grid>
                {params.id && props.pedidoCompra.situacao != 'N' ?
                  <Grid item>
                    <Tooltip title="Clique aqui para liberar os campos de 'Contagem' e 'Pedido' para edição">
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        disabled={props.pedidoCompra.isLoading || !props.pedidoCompra.idFornecedor || props.pedidoCompra.idFornecedor == ''}
                        startIcon={<EditTwoToneIcon />}
                        endIcon={props.pedidoCompra.isLoading && <CircularProgress size={24} />}
                        onClick={() => handleEdit()}
                      >
                        {props.pedidoCompra.isLoading ? 'Editando Pedido de Compra...' : 'Editar'}
                      </Button>
                    </Tooltip>
                  </Grid>
                  : null}
                <Grid item>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={props.pedidoCompra.isLoading || !props.pedidoCompra.idFornecedor || props.pedidoCompra.idFornecedor == ''}
                    startIcon={<SaveIcon />}
                    endIcon={props.pedidoCompra.isLoading && <CircularProgress size={24} />}
                    onClick={(event) => handleSubmit(event, false, true)}
                  >
                    {params.id ? (props.pedidoCompra.isLoading ? 'Atualizando...' : 'Atualizar') : (props.pedidoCompra.isLoading ? 'Salvando Pedido de Compra...' : 'Salvar')}
                  </Button>
                </Grid>
                {/*<Detector*/}
                {/*  render={({ online }) => (*/}
                {/*{(online ?*/}
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.button}
                    variant="contained"
                    disabled={props.pedidoCompra.isLoading || !props.pedidoCompra.idFornecedor || props.pedidoCompra.idFornecedor == ''}
                    startIcon={<SendIcon />}
                    endIcon={props.pedidoCompra.isLoading && <CircularProgress size={24} />}
                    onClick={(event) => handleSubmit(event, false, false)}
                  >
                    {(props.pedidoCompra.isLoading ? 'Carregando...' : 'Revisar e enviar')}
                  </Button>
                  {/*{isOfflineOneTime == true &&
                  <script>
                    {toast('Você está online agora!', {
                      position: 'bottom-left',
                      autoClose: true,
                      toastId: 3,
                      hideProgressBar: true,
                      pauseOnHover: true,
                      progress: undefined,
                      theme: 'light',
                    })}
                  </script>
                  }*/}
                </Grid>
                {/*    :*/}
                {/*    <Grid item>*/}
                {/*      <Button*/}
                {/*        color="primary"*/}
                {/*        className={classes.button}*/}
                {/*        variant="contained"*/}
                {/*        disabled*/}
                {/*        startIcon={<SendIcon />}*/}
                {/*      >*/}
                {/*        Revisar e enviar*/}
                {/*      </Button>*/}
                {/*      <script>*/}
                {/*        {toast('Você está offline! 😥 Conecte-se a internet para prosseguir com o pedido de compra!', {*/}
                {/*          position: 'bottom-left',*/}
                {/*          autoClose: true,*/}
                {/*          toastId: 4,*/}
                {/*          hideProgressBar: true,*/}
                {/*          pauseOnHover: true,*/}
                {/*          progress: undefined,*/}
                {/*          theme: 'light',*/}
                {/*        })}*/}
                {/*        */}{/*{setIsOfflineOneTime(true)}*/}
                {/*      </script>*/}
                {/*    </Grid>*/}
                {/*)}*/}
                {/*/>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Prompt
        when={isModified}
        message={handleBlockedNavigation}
      />
      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>{"Você quer sair dessa página?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Talvez as alterações feitas por você não sejam salvas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDialogClose(true)} variant="contained" color="primary">
            Sair
          </Button>
        </DialogActions>
      </Dialog>

      {
        (modal ?
          <PedidoCompraModal toggle={toggle} modal={modal} props={props} id={modalId} />
          :
          null
        )
      }

      {
        (modalInsumoUnidade ?
          <InsumoUnidadeModal toggle={toggleInsumoUnidade} modal={modalInsumoUnidade} props={props} id={modalIdInsumoUnidade} />
          :
          null
        )
      }

    </Page >
  )
}

PedidoCompraEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedPedidoCompraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PedidoCompraEdit))
)
export { connectedPedidoCompraEditPage as PedidoCompraEdit }
