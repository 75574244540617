import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { lojaAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Paper,
  Grid,
  ButtonBase,
  Typography
} from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import moment from 'moment'

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2),
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 1,
    margin: 'auto',
    maxWidth: 500
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
const LojaStatus = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const { loja } = props.loja
  const { totalRegistros } = props.loja
  const { isLoading } = props.loja

  useEffect(() => {
    dispatch(lojaAction.getLojaStatus());
  }, []);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [props.loja.isLoading])

  return (
    <Page className={classes.root} title="Lojas">
      <AccessControl
        rule={'lojas'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div className={classes.root}>
            <TitleContentInformation
              rule={'lojas.create'}
              length={loja.length}
              subTitle={'GERÊNCIA DE LOJA'}
              title={'Lista do Status das Lojas'}
              href={'/loja'}
            />
            <Grid container className={classes.demo} justify="center" spacing={2}>

              {(
                isLoading ?
                  Array.apply(null, { length: 4 }).map((e, i) => (
                    <Grid key={i} item xl={3} lg={3} md={3} xs={12} >
                      <Skeleton height={220} animation="wave" />
                    </Grid>
                  ))
                  :
                  undefined !== loja && loja.length
                    ? loja.map((n) => {
                      return (
                        <Grid key={n.id} item xl={3} lg={3} md={3} xs={12} >
                          <Paper className={classes.paper}>
                            <Grid container spacing={12}>
                              <Grid item>
                                <ButtonBase className={classes.image}>
                                  {(n.caminhoImagem ?
                                    <img className={classes.img} alt="complex" src={n.caminhoImagem} />
                                    :
                                    null
                                  )}
                                </ButtonBase>
                              </Grid>
                              <Grid item xs={12} sm container style={{ marginLeft: 10, marginTop: 18 }}>
                                <Grid item xs container direction="column" spacing={12}>
                                  <Grid item xs>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                      {n.nome}
                                    </Typography>
                                    <Grid container>
                                      <Typography inline variant="body2">{(n.ativa == true ? 'ATIVA' : (n.ativa == false ? 'INATIVA' : ''))}</Typography>
                                      {(n.ativa == false ?
                                        <Typography inline variant="body2" style={{ color: "#ff0000", marginLeft: 5 }}>⬤</Typography>
                                        :
                                        null
                                      )}
                                    </Grid>
                                    <Typography inline variant="body2" color="textSecondary">{n.tipoIntegracao}</Typography>
                                    <Typography inline variant="body2" color="textSecondary">{n.codigoEstabelecimento}</Typography>
                                    <Typography inline variant="body2" color="textSecondary">{(n.dataUltimaAtualizacaoCardapio ? 'Atualizada em ' + moment(n.dataUltimaAtualizacaoCardapio).format("DD/MM/YYYY HH:mm") : '')}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )
                    })
                    :
                    null
              )
              }
            </Grid>

          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
LojaStatus.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    loja: state.loja
  }
}
const connectedLojaStatusPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(LojaStatus))
)
export { connectedLojaStatusPage as LojaStatus }
