import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  image: {
    padding: 10,
    width: 60
  },
  title: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold'
  },
  subTitle: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
    fontFamily: 'Roboto-Regular'
  },
  info: {
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular'
  },
  details: {
    fontSize: 8,
    textAlign: 'left',
    fontFamily: 'Roboto-Regular'
  },
  infoTop: {
    fontSize: 7,
    fontFamily: 'Roboto-Regular',

    position: 'absolute',
    top: 10,
    left: 20,
    right: 25,
  },
  header: {
    position: 'absolute',
    left: 20,
    right: 25,
  },
});

const font = require('./fonts/Roboto-Regular.ttf')
const fontBold = require('./fonts/Roboto-Bold.ttf')

Font.register({ family: 'Roboto-Regular', src: font, fontStyle: 'normal', fontWeight: 'normal' });
Font.register({ family: 'Roboto-Bold', src: fontBold, fontStyle: 'normal', fontWeight: 'bold' });

const calculateTopOffset = (text, setHeaderHeight) => {
  const baseOffset = 20;
  let additionalOffset = 0;

  if (text) {
    additionalOffset = Math.ceil(text.length / 100) * 4;
  }

  const value = baseOffset + additionalOffset

  setHeaderHeight(value)

  return value;
};

const Header = (props) => {
  const headerTop = calculateTopOffset(props.infoTop, props.setHeaderHeight);

  return (
    <>
      {
        props.infoTop ?
          <View style={[styles.infoTop]} fixed={(props.fixed == null || props.fixed)}>
            <View>
              <Text>
                {props.infoTop}
              </Text>
            </View>
          </View>
          : null
      }

      <View style={[styles.header, { top: headerTop }]} fixed={(props.fixed == null || props.fixed)}>
        <View style={{ flexDirection: "row", width: "100%" }}>

          <View style={{ marginLeft: 5, width: "100%", textAlign: "center" }} >
            <Text style={styles.title}>{props.title}</Text>
            {(props.info != null ? <Text style={styles.info}>{props.info}</Text> : null)}
          </View>

        </View>
      </View>
    </>    
  )

};

export { Header };
