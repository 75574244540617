import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { history } from '../utils'
import { parametroAction } from '../../src/_actions'
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux'
import { InsumoModal } from '../views/Insumos/insumoModal.component';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentInsumo = (props) => {

  const dispatch = useDispatch()

  const { className, ...rest } = props
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('descricao');
  const [direction] = useState('asc');
  const [debounceTerm] = useDebounce(term, 1000);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { parametro } = useSelector(
    (state) => state.parametro
  )

  const options = ['Novo', 'Importar Ficha Técnica'];

  const handleClick = () => {
    history.push('insumo');
  };

  const handleMenuItemClick = (event, index) => {

    setOpen(false);

    if (index == 1) {
      handleOpenModal()
    }

  };

  const handleOpenModal = (id) => {
    toggle();

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction
      };

      dispatch(parametroAction.getParametro(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item>

              <Grid item style={{
                display: 'flex',
                alignItems: 'right',
                flexDirection: 'row',
                textAlgin: 'right',
                justifyContent: 'right',
                spacing: 5
              }}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                  <Button onClick={handleClick}>{options[0]}</Button>
                  <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === 0}
                                disabled={index === 0}
                                onClick={(event) => handleMenuItemClick(event, index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>

            </Grid>
          )}
        />
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}

      {(modal ?
        <InsumoModal toggle={toggle} modal={modal} props={props} parametro={parametro} />
        :
        null
      )}

    </div>
  )
}

TitleContentInsumo.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string
}
export default TitleContentInsumo
